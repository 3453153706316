import * as React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { useGeolocation } from '@websky/core/src/SearchForm/hooks';
import { useGraphQLClient } from '@websky/core/src/graphql-client';
const GeolocationContext = React.createContext(null);
export const useGeolocationContext = () => React.useContext(GeolocationContext);
const GeolocationProvider = ({ children }) => {
    const geolocation = useGeolocation();
    return React.createElement(GeolocationContext.Provider, { value: geolocation }, children);
};
const GeolocationWithApollo = ({ children }) => {
    const client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(GeolocationProvider, null, children)));
};
export default GeolocationWithApollo;
