import React from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyGeolocation from '../CurrencyGeolocation/CurrencyGeolocation';
import css from './Currency.css';
const Currency = () => {
    const { t } = useTranslation('SearchForm');
    return (React.createElement("div", { className: css.container },
        React.createElement("span", { className: css.label }, t('Currency')),
        React.createElement(CurrencyGeolocation, null)));
};
export default Currency;
