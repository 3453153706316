import * as React from 'react';
import { ThemeProvider } from '@websky/core/src/theme';
import BottomRight from '../BottomRight/BottomRight';
import css from './WidgetBottomRight.css';
const WidgetBottomRight = () => {
    return (React.createElement(ThemeProvider, { value: {
            Switch: {
                Switch: {
                    wrapper: css.wrapper,
                    active: css.active,
                    label: css.label
                }
            }
        } },
        React.createElement(BottomRight, null)));
};
export default WidgetBottomRight;
