import React from 'react';
import { useSelector } from 'react-redux';
import { Currency as CurrencyEnum } from '@websky/core/src/enums';
import { useGeolocationContext } from '../../Engine/Geolocation/Geolocation';
import { EU_COUNTRIES_CODES } from '../../Engine/Geolocation/countries';
import { getCurrency, setCurrency } from '@websky/core/src/cache';
import { getIsForeignCard } from '@websky/core/src/SearchForm/store/foreignCard/selectors';
import CurrencyComponent from '@websky/core/src/SearchForm/components/SearchForm/Currency/Currency';
const Currency = () => {
    const isForeignCard = useSelector(getIsForeignCard);
    const { countryCode, loading } = useGeolocationContext();
    const [currentCurrency, setCurrentCurrency] = React.useState(getCurrency());
    const onSetCurrency = React.useCallback((currency) => {
        setCurrentCurrency(currency);
        setCurrency(currency);
    }, []);
    React.useEffect(() => {
        let currencyByCountry;
        if (typeof countryCode === 'string') {
            if (countryCode === 'KZ') {
                currencyByCountry = CurrencyEnum.KZT;
            }
            else if (countryCode === 'RU') {
                currencyByCountry = CurrencyEnum.RUB;
            }
            else if (EU_COUNTRIES_CODES.includes(countryCode)) {
                currencyByCountry = CurrencyEnum.EUR;
            }
            else {
                currencyByCountry = CurrencyEnum.USD;
            }
        }
        if (currencyByCountry && currencyByCountry !== currentCurrency) {
            onSetCurrency(currencyByCountry);
        }
    }, [countryCode]);
    React.useEffect(() => {
        if (isForeignCard && currentCurrency !== CurrencyEnum.KZT) {
            onSetCurrency(CurrencyEnum.KZT);
        }
    }, [isForeignCard]);
    return (React.createElement(CurrencyComponent, { key: currentCurrency, defaultCurrency: currentCurrency, isDisabled: loading || isForeignCard, onSetCurrency: setCurrentCurrency }));
};
export default Currency;
