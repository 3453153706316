export const zh = {
    Account: {
        Account: '个人帐户',
        Email: '电子邮箱地址',
        Phone: '电话号码',
        'Please enter your phone number': '请输入您的电话号码',
        'Please enter your email': '请输入您的电子邮箱',
        'Contact details are necessary for us to be able to pass on the latest flight information.': '需要提供联系信息，以便您收到最新的航班信息。',
        'Add login through your social network account': '添加通过社交帐户登录。',
        'I want to receive news and special offers': '我想接收新闻和特别优惠',
        'Load personal data according to the standard of GDPR': '按照GDPR标准上传个人数据',
        Terms: '规则',
        'Delete my account': '删除帐户',
        'Are you sure?': '您确定？',
        'Are you sure you want to delete your account?': '您确定删除帐户？',
        'Yes, Delete account': '是的，删除帐户',
        'Please enter a valid email': '请检查电子邮箱地址',
        'Please enter a valid phone': '请检查电话号码',
        'Confirmation code': '验证代码',
        'We sent verification code to new email': '我们已向新电子邮件地址发送了确认码。',
        'We sent verification code to the phone': '我们已向您的手机发送验证码',
        'Didn\'t get the code?': '没收到代码？',
        'Resend it': '重新发送',
        Orders: '我的订单',
        'Please confirm your email': '请确定自己的电子邮箱',
        'Please confirm your phone number': '请确定自己的电话号码',
        Confirm: '确定',
        Save: '保存',
        Send: '发送',
        'Travel companions': '同路人',
        Logout: '退出',
        'Personal details': '个人档案',
        Settings: '设置',
        'Incorrect card number or password': '卡号或密码错误',
        'Loyalty program': '会员计划',
        FFP: '会员计划',
        'Loyalty card number': '会员卡号',
        Password: '密码',
        'Client support': '帮助',
        No: '不是',
        'Log out': '退出',
        'Phone is already in use by another account': '此手机已在另一个帐户中使用',
        'Email is already in use by another account': '该电子邮件已在另一个帐户中使用',
        'Failed to fetch': '执行请求时发生错误',
        'Oops...': '哎呀…',
        'OK, thank you': '明白，谢谢',
        'An error occurred while executing the request. Please try again later or contact a customer support service.': NaN,
        'Error on confirming': '验证码无效',
        'My cabinet': '主菜单',
        'Orders that were booked on a previous version of the site are available': '在网站先前版本上下的订单可在…查看',
        'this link': '链接',
        'Operations history': '操作历史',
        Date: '日期',
        Operation: '操作',
        Balance: '可用余额',
        'Status miles': '状态英里',
        Assessed: '已加算',
        'Add booking': '添加预订',
        'Ticket or booking number': '机票或预订号',
        'Online check-in': '在线登机',
        'Search flights': '搜索航班',
        'I have a booking': '我已有预订',
        'Check-in': '登机手续',
        'How to find a booking id?': '我如何找到我的预订 识别？',
        'Check-in conditions': '登机条件',
        Cancel: '取消',
        Continue: '继续',
        'Last name': '姓',
        'Enter the passengers surname (in latin characters) and ticket number, order ID or booking ID. You can find these in the itinerary receipt sent to purchasers Email after booking.': NaN,
        'Online check-in opens 30 hours and closes 50 minutes before departure': '在线办理登机手续于起飞前 30 小时开放，于起飞前 50 分钟关闭。',
        'Baggage claim closes 40 minutes before flight departure. Please, check that your hand luggage weight is up to 10 kg and size does not exceed 55x40x23 cm': NaN,
        'Please, make sure te passenger is not included in special category': '确保乘客不属于特殊类别。',
        'Purchase for miles': '使用英里购买',
        'Please fill in the missing fields': '请填写缺失的字段',
        'Confirm your contacts': '请确定自己联系方式',
        'Set your preferences': '提出您的宁愿',
        'Add your phone number': '请添加自己的电话号码',
        'Keep building my profile': '继续填写您的个  人资料',
        'Enter your name': '请填写您的名字',
        'Welcome!': '欢迎光临！',
        'Usually traveling with a friend or family?': '您经常与家人或朋友一起旅行吗？',
        'Add them to your companions list and make checking-in altogether easier.': '将您的旅行同伴添加到此列表中以加快数据输入过程',
        Passengers: '乘客',
        'Add companion': '请添加同伴',
        'Next flights': '最近的飞行',
        'My office': '我的个人帐户',
        Home: '主页面',
        'Login settings': '登录设置',
        'Social networks accounts': '通过社交网络登录',
        'Mail settings': '分送',
        Profile: '个人帐户',
        Edit: '更改',
        Miles: '英里',
        Communications: '通信',
        Connect: '连接',
        Connected: '已连接',
        'Not connected': '不连接',
        'Email will be removed from your login methods': '邮箱{{邮箱}} 将从登录方法中删除',
        'Phone will be removed from your login methods': '电话{{电话}} 将从登录方法中删除',
        'FFP will be removed from your login methods': '会员卡 {{会员计划}} 将从登录方法中删除',
        'Login method removal': '删除登录方法',
        Remove: '删除',
        'method will be removed from your login methods': '{{方法}} 将从登录方法中删除',
        'Add a new login method': '添加新的登录方法',
        Add: '添加',
        'A confirmation code will be sent to your address': '确认码将发送至您的电子邮箱',
        'A confirmation code will be sent to your phone': '确认码将发送至您的电话',
        'Change login method': '更改登录方法',
        'Error on sending secure code': '发送验证码时出错',
        'Email for notification': '通知邮箱'
    },
    AdditionalBaggage: {
        'More bags?': '需要办行李？',
        'Buy now and save 20-30%': '现在节省高达 30%',
        'Check-in bags': '行李',
        'Leave them at the check-in desk. Up to 3 bags per passenger. Price per flight.': '将它们留在值机柜台。每位乘客最多可携带 3 件行李。价格按航班计算。',
        Add: '添加',
        from: '自从',
        Edit: '更改',
        total: '一共',
        'Check-in baggage': '行李',
        'More baggage for you': '请选择行李',
        Overweight: '超额行李',
        'Need to add another 10-30 kg to your total checked baggage?': '为您的行李额外添加 10-30 公斤'
    },
    AdditionalServiceCard: {
        Add: '添加',
        Remove: '删除',
        Insurance: '保险',
        Transfer: '航空快运',
        'Terms of service': '条件',
        Select: '选择',
        Selected: '已选择',
        'Enter document number': '请选择个人识别号',
        Required: '请填写字段',
        'Only digits': '请输入12位数字',
        Done: '完毕',
        Clear: '清除'
    },
    AdditionalServiceWrapper: {
        'Full conditions': '完整的条件',
        Cancel: '取消',
        Ok: '确定',
        Clear: '清除',
        Confirm: '确定',
        Total: '一共'
    },
    AddPassenger: {
        'Add passenger': '添加乘客',
        'Passenger last name': '乘客姓',
        'Ticket number or booking reference': '机票号码或预订代码',
        'Ticket number': '机票号吗',
        Find: '寻找',
        'Required field': '必填',
        'Passenger not found': '乘客未找到'
    },
    Aeroexpress: {
        ToAirport: '去往机场',
        'To airport': '去往机场',
        FromAirport: '从机场',
        'No worries about traffic jams, arrival at the airport strictly on schedule': '不用担心堵车，准时到达机场',
        Add: '添加',
        'Fast and convenient': '快捷方便',
        Transfer: '航空快运',
        seat_0: '{{数量}} 座位',
        seat_1: '{{数量}} 座位',
        seat_2: '{{数量}} 座位',
        ticket_0: '{{数量}} 机票',
        ticket_1: '{{数量}} 机票',
        ticket_2: '{{数量}} 机票',
        Cancel: '拒绝',
        Total: '一共：',
        From: '自从',
        'View schedule': '查看时间表',
        Terms: '条件',
        Change: '改变',
        Done: '完毕',
        Clear: '清除',
        'Download tickets': '下载机票',
        standart: '标准',
        business: '商务',
        Download: '下载',
        full: '标准',
        roundtrip: '往返',
        Roundtrip: '往返',
        To: '到'
    },
    Aircraft: {
        Embraer: '安博威',
        'E190 E2': 'E190 E2',
        Economy: '经济',
        Business: '商务',
        Premium: '高级',
        'Seat pitch': '座椅深度',
        'Up to': '到',
        '"': '"',
        'Seat width': '座椅宽度',
        'Seat recline': '座椅后仰',
        'The Embraer E-Jet E2 family are medium-range jet airliners developed by Embraer, succeeding the original E-Jet. The program was launched at the Paris Air Show in 2013. The first variant, the E190-E2, took its first flight on 23 May 2016 and was certified on February 28, 2018 before entering service with Widerøe on 24 April. The three twinjet variants share the same four-abreast narrow-body fuselage with different lengths and three different new wings, Pratt & Whitney PW1000G turbofans in two sizes, fly-by-wire controls with new avionics, and an updated cabin.': NaN,
        Max: '最大',
        nmi: '最小',
        ft: '英尺',
        cm: '厘米',
        'National origin': '生产',
        Brasil: '巴西',
        Manufacturer: '制造商',
        Status: '状态',
        'In service': '服务中',
        Produced: '已生产的',
        'Flight deck crew': '组队',
        '2 pilots': '两名飞行员',
        'Seating, dual class': '双舱座位',
        '96 12j @38in': '96 12j @38英寸',
        '18.3 in (46 cm)': '18.3 英寸 (46 厘米)',
        Length: '长度',
        '36.24 m': '36.24 米.',
        Wingspan: '翼展',
        '33.72 m': '33.72 米.',
        Speed: '速度',
        'Mach 0.82 (473 kn; 876 km/h) Max': '最多 0.82 (473; 876 公里/小时) 最多.',
        'Range (full pax)': '飞行距离',
        '3,250 nmi (6,020 km)': '3,250 最少 (6,020 公里)',
        'Service ceiling': '最大高度',
        '41,000 ft (12,000 m)': '41,000 英尺 (12,000 米)',
        Engines: '发动机',
        '2× Pratt & Whitney PW1919G/21G/22G/23G': '2× 普惠 PW1919G/21G/22G/23G',
        Title_PassengerCapacity: '容量',
        Title_CruisingSpeed: '速度',
        Title_MaxFlightRange: '距离',
        Unit_PassengerCapacity: '座位',
        Unit_CruisingSpeed: '公里/小时',
        Unit_MaxFlightRange: '公里'
    },
    Airport: {
        'Open web site': '打开网站',
        Departures: '起飞',
        Arrivals: '抵达',
        Summary: '信息',
        Services: '服务',
        Map: '地图'
    },
    BaggagePage: {
        Baggage: '行李',
        'Transportation of impressions no limitations': '运输印象不限制',
        'Baggage rules': '行李运费规则',
        Adult: '成年人',
        Child: '儿童',
        Infant: '婴儿'
    },
    BaggageService: {
        'Set the same baggage for each flight': '所有方向的行李数量相同',
        'Set the different baggage for each flight': '所有方向的行李数量不同',
        'If the dimensions of your carry-on baggage exceed the permitted dimensions of 40x30x20 cm, you can increase its size up to 55x40x20 cm': NaN,
        'Sports equipment': '运动器材',
        'Add sports equipment': '添加运动器材',
        'Your can bring your ski, snowboard, surf equipment, bicycle, etc. packed in a pouch': '您可以在行李箱中携带滑雪板、滑单板、自行车',
        included: '包括',
        Add: '添加',
        Selected: '已选择',
        kg: '公斤',
        'pcs.': '件',
        Overweight: '超额行李重量',
        'Improve baggage weight you can bring to airplane': '如果您的行李超过允许重量，您可以增加重量',
        '{{value}} kg': '{{数值}} 公斤',
        'Checked-in baggage': '已登机的行李',
        'up to {{value}} kg': '到 {{数值}} 公斤',
        'Increasing the size of carry-on baggage': '增加手提包的尺寸',
        'You can bring your ski, snowboard, surf equipment, bicycle, golf bag, etc.': NaN,
        Done: '完毕',
        Clear: '清除',
        RUB: '卢布',
        USD: '美金',
        from: '自从',
        'The service is applied to directions': '该服务适用于...方向',
        'The service is applied to all directions': '该服务适用于所有方向',
        CarryOn: '手提包',
        Baggage: '行李',
        SportingEquipment: '特种设备'
    },
    BaggageTotalPriceBlock: {
        Add: '添加',
        edit: '改变',
        from: '从',
        'More bags': '行李'
    },
    Cart: {
        Flights: '飞行',
        Baggage: '行李',
        Seats: '座位',
        Meal: '饮食',
        Passengers: '乘客',
        Contacts: '联系方式',
        'All flights': '所有航班',
        Total: '一共：',
        Close: '关闭',
        ADT: '成年人',
        CLD: '儿童',
        INF: '婴儿',
        INS: '有座位的婴儿',
        Extra: '补充地',
        Aeroexpress: '航班快运',
        Insurances: '保险',
        'Business lounge': '商务休息室',
        'The service you have added cannot be activated. Try connecting later or contact the airline operator.': NaN,
        'The service is awaiting confirmation. Do not close or refresh the window.': '该服务正在等待确认。不要关闭或刷新窗口'
    },
    Checkin: {
        'Oops, something went wrong': '糟糕，出了点问题',
        'But don\'t worry, all of our systems have been duplicated several times. You can register on the backup version of the site.': NaN,
        Go: '转到备份网站',
        'Try again': '重试',
        From: '从',
        'Please, enter your contacts': '请输入您的联系方式',
        'Ticket number or booking reference': '机票号码或预订代码',
        'Inflight insurance or baggage insurance is your travel confidence.': '航班保险或行李保险让您在旅行时安心无忧。',
        'Checkin canceled, you will be redirected to the beginning': '注册已取消，您将被重定向到开头',
        'Passenger last name': '乘客姓',
        'Oops...': '哎呀…',
        Continue: '继续',
        'OK, thank you': '明白，谢谢',
        'An unexpected error has occurred during the start of the check-in process. Please try again later or contact a customer support service.': NaN,
        'Online check-in': '在线登机',
        Passengers: '乘客',
        Next: '下一步',
        'Find booking': '搜索订单',
        Baggage: '行李',
        Seats: '选择座位',
        Meal: '饮食',
        Payment: '付款',
        'Boarding pass': '登机牌',
        'Choose passengers for check-in': '选择要办理登机手续的乘客',
        'How to find a booking?': '如何找到订单？',
        'Enter the passenger last name (in latin characters) and the booking code. \nYou can find it in the itinerary receipt sent to your email after booking.': '在表格中注明机票号码或预订代码以及乘客的姓。\n之后，单击“搜索”按钮。',
        'Incorrect last name or booking code': '姓或预订代码无效。',
        'Check-in Successful': '登机完成。',
        'Sorry,': '很抱歉，',
        'online check-in is closed for this flight': '该航班的网上值机已关闭',
        OK: '好的',
        'You can now download and print your boarding passes. You will need them at the airport.': NaN,
        'An error occurred during the check-in process. Please try again later or contact a customer support service.': NaN,
        'Anything else for your trip?': '还要别的吗？',
        Extras: '附加服务',
        'Online check-in will close at': '网上预办登机结束在…',
        'To complete online check-in you must choose seats for all passengers on all flight directions': NaN,
        'Unfortunately, check-in cancellation is prohibited for this flight. Please contact a customer support service for further details.': NaN,
        'Please, enter your visa details': '请填写您的签证详细信息',
        Save: '保存',
        Download: '下载',
        'Go to home': '去主页面',
        'Business lounge': '商务休息室',
        from: '从',
        Change: '改变',
        'No visa required': '跳过',
        'Start online check-in': '在线登机',
        'Last name': '姓',
        'Ticket / booking number': '机票/预订号',
        'Booking not found': NaN,
        Required: '必填',
        'Incorrect value': '无效值',
        'How to find a booking id?': '如何找到预订号？',
        HelpBookingFind: NaN,
        Conditions: '条件',
        Add: '添加',
        Cancel: '取消',
        'airport local time': '机场当地时间',
        'Seats have already been assigned by the airline and check-in is only available at the counter at the airport': '座位已由航空公司分配，仅可在机场柜台办理登机手续',
        Attention: '注意',
        Close: '关闭',
        'Invalid seat number': NaN,
        'You can now download and print your boarding passes. You will need them at the airport.\n Please notice, for flight {{flight}} you must receive your boarding pass at the airport': NaN,
        'You can now download and print your boarding passes. You will need them at the airport.\n Please notice, for flight {{flight}} you must receive your boarding pass at the airport_1': NaN,
        'Service is temporarily unavailable. There are no seats available. Please try again later, or check in at the airport.': NaN,
        'Checkin available only in airport': NaN,
        'You cannot add a passenger because the currency of order is different from the currency of orders of previously added passengers.\nYou can check-in the passenger separately.': NaN
    },
    CheckinAddDocuments: {
        Submit: '保存',
        'Add documents': '添加文件'
    },
    CheckinConditions: {
        'Check-in conditions': '登机条件',
        OpenCloseCheckinCondition: '网上办理登机手续于起飞前 30 小时开始，于起飞前 50 分钟关闭',
        BaggageCondition: NaN,
        PassengersCondition: '确保乘客不属于特殊类别'
    },
    CheckinRules: {
        Continue: '继续',
        'Terms & Conditions': '在线登机条款及规则',
        'I have read and agreed to the online check-in terms and conditions': '我已阅读并同意在线登机的条件',
        'Please read and accept the online check-in terms and conditions': '请阅读并同意在线登机的条件'
    },
    Checkout: {
        'Review your trip': '您的订单',
        'LOUNGE ACCESS': '商务舱',
        'PRE RESERVED SEAT ASSIGNMENT': '选择座位',
        Details: '详情',
        'Miles will be credited to your account after the flight.': '完成飞行后，英里将记入您的个人帐户。',
        'Start earning miles now to flight cheaper': '开始赚取英里以更便宜的价格飞行',
        Register: '登机',
        'View status': '查看状态',
        '{{number}} {{miles}} will be awarded': '将记入 {{号}} {{英里}}',
        miles: '英里',
        miles_0: '英里',
        miles_1: '英里',
        miles_2: '英里',
        'Light Jet Club': 'Websky Club',
        'Get our latest offers': '专门为您',
        Cancel: '拒绝',
        'Sign up for NORDWIND newsletter and enjoy invites to major sales and exclusive offers and always be in the know.': NaN,
        'I accept the': '我与……同意',
        'terms of agreement': '合同条款',
        'terms of insurances': '保险条款',
        'Sorry, the price has changed to': '很可惜，价格降到',
        'Wow, the price has changed to': '哇，价格已经降到',
        'We recommend buying your tickets now to avoid another price increase.': '价格随时可能上涨，不要犹豫购买！',
        'Sorry, flight is either not available or sold out': '真可以，该航班已无座位。',
        'Please, choose another flight.': '请选择其他航班。',
        'Choose another flight': '选择其他航班。',
        'old price': '之前的价格',
        'Change flight': '换航班',
        Continue: '继续',
        Required: '必填',
        'Please, read the rules of service and accept it': '要继续，请阅读并同意服务条款',
        Seats: '座位',
        Baggage: '行李',
        Meal: '饮食',
        'Select seats': '选择座位',
        'Select meal': '选择饮食',
        'Checked-in baggage': '补充的行李',
        Adult: '成年人',
        Child: '儿童',
        Infant: '婴儿',
        Status: '订单状态',
        'Payment details': '价格详细化',
        Service: '服务',
        'processing of my personal data': '处理个人数据的条件',
        'general transportation rules': '运输总则',
        'and I accept it. I am aware that the fee for unused additional services is not refundable.': NaN,
        and: '与',
        Price: '价格',
        Total: '一共',
        Booked: '已预订',
        Cancelled: '已取消',
        Confirmed: '已订单',
        New: '新的',
        'Thank you for your booking!': '机票已成功出票！',
        Back: '往后',
        'Priority boarding': '优先着陆',
        Insurance: '保险',
        Transfer: '接送服务',
        Aeroexpress: '航空快运',
        Flight: '飞行',
        Flights: '飞行',
        'Selected options': '已选择的飞行',
        Clarification: '请注意',
        'Skip the line at the gate and board the aircraft early.': '先到先得登机，无需排队。',
        'Guaranteed overhead baggage space.': '保证行李空间。',
        'More time to get comfortable on board.': 'Посадка до остальных пассажиров.',
        'Coverage for medical expenses, also suitable for visa. Business lounge in case of delay. Get 30 miles': '涵盖医疗案件以及签证情况。获得额外 30 英里',
        'No worries about traffic jams, arrival at the airport strictly on schedule.': '不用担心交通堵塞。落地后立即送往市中心。',
        'Be among the first to board': '第一批乘客中',
        'Basic Packet': '基本套餐',
        'Fast and convenient': '快捷方便',
        'Are you sure you are ready to remove insurance?': '您确定准备好放弃保险了吗？',
        'Inflight insurance or baggage insurance is your travel confidence.': '航班保险或行李保险让您在旅行时安心无忧。',
        'Flying with risk': '我自己承担所有风险',
        Insure: '留下保险',
        Add: '添加',
        'Download policy': '下载保险',
        'Show details': '查看细节',
        'Hide details': '隐藏细节',
        'Go to online check-in': '在线登机',
        'Change / Refund': '换/退还？',
        'Tickets have been sent to {{email}}': '机票已发送到{{电子邮箱}}',
        'Resend it': '重新发送',
        'Enjoy the extended range of dishes and top-notch services on board, earn extra miles': NaN,
        Passengers: '乘客',
        'Oops...': '哎呀…',
        'OK, thank you': '明白，谢谢',
        'An unexpected error has occurred during the booking process. Please try again later or contact a customer support service.': NaN,
        'An unexpected error has occurred during the tickets downloading process. Please try again later or contact a customer support service.': NaN,
        Send: '发送',
        Email: '电子邮箱地址',
        'Resend booking': '发送机票',
        'Failed to send tickets to the specified mail, try again later': '发送机票至指定邮箱失败，请重试',
        'Please enter a valid email': '检查输入的电子邮箱地址',
        'Select seat': '选择座位',
        'If you will not select a seat now, we will automatically select it for you upon check-in.': '我们建议您立即选择座位，最好的座位优先！',
        'Next step': '转到办理服务',
        'Now you will be redirected to the previous version of the site. There you can choose ancillary services.': NaN,
        'Add now': '选择服务',
        'Order has been cancelled': '订单已被取消',
        'Your special comfort on board': '机上的舒适',
        'Improve your flight right now by adding the pleasant and necessary comfort with special food, extra baggage and the best places with amazing views.': NaN,
        Resend: '重新发送',
        'Thank you for your choice. All need documents has been sent to your e-mail address.': '感谢您的选择。所有必要的文件已发送至您的电子邮件。',
        'Paid by credit card': '已用卡支付了',
        'Waiting for payment expires at': '等待付款到期时间为',
        Ref: '订单号',
        'Security code': '访问代码',
        Download: '下载',
        'Price details': '一共',
        'Baggage rules': '运送行李规则',
        'Feel the taste in flight!': '感受飞行中的味道！',
        'Transportation of impressions no limitations': '运输印象不受限制',
        'The top ones get the top seats!': '最好的地方去第一个！',
        'Fly in comfort': '在舒适的氛围中飞行',
        from: '从',
        Change: '更改',
        Refund: '退还',
        Exchange: '换',
        'Seat selection is available in the online registration service': '可通过在线登机服务选择座位',
        'You can select seats using the online registration service after issuing tickets': '机票后，您可以使用在线值机服务选择座位。',
        'Business lounge': '商务休息室',
        'The flight is available, hurry up with booking!': '座位还有空的，抓紧时间预订吧！',
        'Attention, times have changed for flight {{route}}': '请注意！航班状态已更改 {{航线}}',
        'More details': '详情',
        'Capsule hotel': '胶囊旅馆',
        'per person': '每个人的',
        'Full conditions': '完全条件',
        'Total for all passengers': '所有乘客总计',
        Aeroexpress_Cancel: '取消',
        Ok: '好的',
        Class_full: '标准',
        Class_business: '商务',
        Class_child: '儿童',
        Class_family: '家庭',
        Class_couple: '双人',
        Class_standart: '标准',
        Class_roundtrip: '标准',
        Class_business_roundtrip: '商务',
        ToAirport: '单程的',
        FromAirport: '单程的',
        Roundtrip: '往返',
        'And back': '回城的',
        'Aeroexpress tickets are valid for 30 days.': '机场快轨机票的有效期为 30 天。',
        'passenger conduct rules': '乘客行为规则',
        'terms of tariff': '票价条件',
        'Search my booking': '搜索订单',
        Done: '完毕',
        'Passenger not found. Please, check passenger last name and ticket number': '未找到乘客，请检查您输入的详细信息',
        'Add passenger': '添加乘客',
        'Add your travel companion to the order? Add ticket': '该订单添加乘客',
        'price for all persons': '所有人的价格',
        Select: '选择',
        Reject: '取消',
        'SMS notifications': '短信提醒',
        Clear: '清除',
        'Open the full text of the conditions of carriage': '打开运输条件全文',
        Animal: '宠物',
        'All payments will be charged in Russian rubles. The amounts indicated in other currencies are presented as a guideline.': NaN,
        'Only 1 animal is allowed per adult passenger.': '每位成年乘客仅允许携带 1 只宠物。',
        'Placement of passengers with an animal in the front rows is prohibited.': '禁止将携带宠物的乘客安排在第一排。',
        'Specify all passengers for whom you need to open an order for correct display.': '指定您需要为其打开订单才能正确显示的所有乘客。',
        'View old booking': '打开相关的订单',
        'Order created from': '订单创建从……日期',
        'You must select seats to proceed to the next step': '选择座位以继续下一步',
        'All flight': '所有的航班',
        'Promo code': '促销代码',
        Passenger: '乘客',
        'Original price': '原价',
        'Final price': '折扣价',
        'Total price': '最终价格',
        'Correct promo codes': '修改促销代码',
        'Check if the promo code is entered correctly or contact the call center of the airline': NaN,
        'Add services': '补充服务',
        Error: '错误',
        'An error occurred while checking the promo code, change the promo code or continue without it': '检查促销代码时出错，请更改促销代码或不使用促销代码继续。',
        'Promo codes not available': '促销代码无效',
        'We recommend using extra baggage': '我们建议使用额外行李“{{名字}}”',
        'Extra delivery baggage': '我们建议使用新服务\n “{{名字}}”，轻装上阵！',
        'Extra delivery baggage free for dates': '该服务对于 2024 年 4 月 18 日至 2024 年 4 月 20 日期间的航班免费',
        Refuse: '拒绝',
        Agree: '添加服务',
        Remove: '删除',
        'Prices are for all passengers, including taxes and fees. By local time.': '价格适用于所有乘客，包括税费。当地时间。',
        'Error from supplier. Passenger\'s name and/or surname is too long': '乘客的名字和/或姓超出了允许的字符数',
        Confirm: '确认',
        'Extra comfort': '补充的舒适',
        'VIP lounges, business lounges, priority pre-flight procedures': '贵宾休息室、商务休息室、加急飞行前手续',
        'To book': '预订',
        'Your order was archived. Please contact with client support to get a receipt': NaN,
        pet_popup_rules: '[名单]',
        'Miles are not credited for this flight.': '此航班不奖励英里。',
        'Baggage delivery': '行李运送',
        'per place': '每件行李',
        'All passengers': '所有的乘客',
        'Proceeding to payment...': '继续付款…',
        'An order for this flight and the set of passengers already exists': '包含这组乘客的该航班的订单已存在',
        'У разных элементов Traveller не может быть одинакового значение параметра LinkedTo, большего чем 0': NaN,
        'You are not logged in': '您没登录',
        'Add protection': '添加保护',
        'Remove protection': '删除保护',
        'For all passengers': '所有的乘客',
        'Want to add extra protection while traveling?': '想在旅行时添加额外的保护吗？',
        InsuranceGroupTitle_default: '保险',
        InsuranceGroupDescr_default: '航班延误或取消时的费用补偿',
        InsuranceGroupTitle_common: '航班变更保险',
        InsuranceGroupDescr_common: '如果取消或更改旅行日期，可获得<稳定的>最高 40,000 卢布</稳定的>的赔偿。',
        InsuranceGroupTitle_flight: '综合飞行保险',
        InsuranceGroupDescr_flight: NaN,
        InsuranceGroupTitle_med: '健康保险',
        InsuranceGroupDescr_med: NaN
    },
    CheckoutAdditionalService: {
        Add: '添加',
        from: '从',
        Exclusive: '独家的'
    },
    CheckoutOrderSummary: {
        'Order must be paid': '订单需支付',
        before: '到',
        'today before': '今天到',
        'View details': '飞行细节',
        'Order number': '订单号'
    },
    Communications: {
        Communications: '通讯',
        Notifications: '通知',
        'My appeals': '我的请求',
        Chat: '聊天室',
        Promotions: '通知',
        'Order notifications': '关于订单的通知',
        'My promo codes': '我的促销代码',
        Back: '往后',
        'Mark all as read': '全部标记为已读',
        Updated: '已更新',
        Created: '已创建',
        Status: '状态',
        'Call to action': '号召性用语',
        Order: '订单',
        'Book with promo code': '用促销代码订单',
        Personal: '个人的',
        Reusable: '可重复使用的',
        New: '新的',
        WaitingAnswer: '等待回复',
        InProgress: '在工作中',
        Solved: '已完成',
        Rejected: '已取消',
        Used: '已使用',
        Expired: '到期了',
        'Can be used until': '可以到……使用',
        ID: '识别号',
        Oops: '哎呀……',
        'Something went wrong': '出了点问题',
        'Mark as read': '标记为已读',
        'There is nothing yet': '还没有什么',
        Empty_my_appeals: '您还没有请求',
        Empty_promotions: '您还没有任何营销通知',
        Empty_order_notifications: '您还没有订单',
        Empty_my_promo_codes: '您还没有促销代码'
    },
    CompareFares: {
        Label: '按键',
        'Internal error': '内部错误',
        'Compare fare families': '票价族比较',
        Economy: '经济',
        Light: '轻',
        Classic: '经典',
        Flex: '灵活的',
        Business: '商务',
        Premium: '高级',
        Baggage: '行李',
        'Checked baggage': '已登记的行李',
        Services: '服务',
        'Ticket changes': '机票变更',
        Cancellation: '取消',
        'In-Cabin service': '上机的服务',
        'Comfort kit': '舒适套装',
        'Airport sevices': '机场的服务',
        'Priority boarding': '优先着陆',
        'Priority baggage delivery': '优先行李运送',
        'Lounge service': '休息室',
        'View full fare conditions': '票价的完全比较',
        Note: '备注',
        'Go back': '返回',
        'The fare difference is calculated taking into account the prices available through our global reservations system, not the airBaltic website': NaN,
        'Please see detailed baggage rules that apply to airBaltic operated flights here': NaN,
        'Passengers in possession of roundtrip tickets who have flown or are planning to fly one way only and wish to receive a refund for the unused part of the ticket, please make sure that the ticket type supports cancellation with refund. In such cases the value of the used portion of the ticket will be recalculated to reflect the price of a one-way ticket, and the remaining difference will be refunded after subtracting any cancellation fees, if applicable to the particular ticket type.': NaN,
        'Name and/or surname change or correction is only possible if the ticket issued is an airBaltic document and the booking consists of flights operated by airBaltic.': NaN,
        'Applicable for tickets booked after 18th of February 2018, for tickets booked before 19th of February, cancellation allowed anytime. A refund fee of 100 EUR applies to each ticket.': NaN,
        'For tickets that are purchased until 31.05.2018, a transition period from 01.06.2018-30.06.2018 is applicable. During this period fee is EUR 50.': NaN
    },
    Consents: {
        agreement_gdsRules: '与<a href=\'#\' target=\'_blank\'>附加服务条件</a>',
        agreement_insurancesRules: '<a href=\'#\' target=\'_blank\'>保险规则</a>，以及 {{insurancesProductsLinks}} 的条款',
        agreement_generalRules: '<span><a href=\'#\' target=\'_blank\'>购买的规则</a></span>',
        agreement_interpolation: '{{总则}}{{保险规则}}{{附加服务规则}}',
        agreement_rules: '我已阅读{{链接}}，这些附加服务的付款，接受并同意。',
        agreement_additionalInsuranceRules: '公开发售条款 {{链接}}',
        agreement_additionalInsuranceName: '«{{名字}}»'
    },
    Contacts: {
        'Your contacts': '联系方式',
        'Contact details are necessary for us to be able to pass on the latest flight information.': '预订信息需要联系方式。',
        Email: '电子邮件',
        Phone: '电话',
        'We sent SMS with verification code to the phone': '请输入短信中的确认码',
        'Didnt get the code? ': '没收到代码？',
        'Resend it': '重新发送',
        Edit: '更改',
        'Invalid code.': '不正确的代码',
        'Want to speed up passenger data entry? Log in and continue.': '登录以保存您的详细信息，下次不再输入',
        'Want to speed up passenger data entry? Sign up and continue.': '注册以保存您的详细信息，以便下次无需输入它们',
        'Want to speed up passenger data entry?': '想要加快输入乘客的数据？',
        'Log in': '登录',
        'Sign up': '注册',
        Next: '下一步',
        'You can get the new code in': '新的代码可以过……发送',
        'Sending message...': '正在发送代码',
        'Code sent': '代码已发送',
        'Welcome!': '欢迎光临！',
        'Want to speed up passenger data entry? Sign in and continue.': '想要加快您的预订流程吗？登录。',
        'Continue without signing in': '无需登录即可继续',
        'Sign in': '登录',
        'Phone number of the same digits is not allowed': '请输入正确的电话号码',
        'Incorrect phone number': '请输入正确的电话号码',
        'Min 10 digits': '最少10位数字',
        'Max 18 digits': '最多18位数字',
        'Example +1234567890': '比例：+1234567890',
        'Incorrect email': '请输入正确的电子邮箱地址',
        Required: '必填',
        'Receive a mailing list with promotional offers and discounts': '我同意通过电子邮件接收包含折扣和优惠的新闻通讯'
    },
    CustomerContacts: {
        'Please, enter your contacts': '请您输入您的联系方式'
    },
    CustomerContactsForm: {
        'Please, enter your contacts': '请您输入您的联系方式',
        Done: '完毕',
        Email: '邮箱',
        Phone: '电话',
        Required: '必填',
        'Please enter a valid email': '请电子邮箱',
        'Please enter a valid phone': '请检查电话号码',
        'Please enter a valid FFP': '请检查会员卡号'
    },
    Datepicker: {
        month_0: '一月',
        month_1: '二月',
        month_2: '三月',
        month_3: '四月',
        month_4: '五月',
        month_5: '六月',
        month_6: '七月',
        month_7: '八月',
        month_8: '九月',
        month_9: '十月',
        month_10: '十一月',
        month_11: '十二月',
        week_short_0: '星期一',
        week_short_1: '星期二',
        week_short_2: '星期三',
        week_short_3: '星期四',
        week_short_4: '星期五',
        week_short_5: '星期六',
        week_short_6: '星期日'
    },
    DesktopFlight: {
        Select: '选择',
        'Select fare': '选择票价',
        Arrival: '抵达',
        stop: '换乘',
        stops: '换乘',
        Nonstop: '直飞',
        Details: '详情',
        'Need_f ': '要',
        'Need_m ': '要',
        'the ability to refund ticket': '可以退票',
        'the ability to exchange ticket': '可以换票',
        baggage: '行李',
        and: '和'
    },
    DesktopFlightInfo: {
        Terminal: '航站楼',
        Duration: '在行程中',
        'Arrival the next day': '下日抵达',
        'Eco-friendly': '环保的',
        'Service is unavailable on this flight': '该航班不提供该服务',
        TechnicalLandingIn_0: '在……技术性着陆',
        TechnicalLandingIn_1: '在……技术性着陆',
        'Total duration': '行程时间',
        'Stop in': '在……换乘',
        'Select from': '从……选择',
        Select: '选择',
        from: '从',
        Canceled: '已取消',
        'taxes and fees': '收费',
        'Operating carrier': '执行航班的',
        SubStatus_AwaitingExchange: '交换过程中',
        SubStatus_Upgrade: '升级过程中'
    },
    DesktopSummaryFlight: {
        'Change flight': '选择别的航班',
        'Fare rules': '使用票价的规则',
        'Baggage info': '行李信息',
        Details: '详情',
        Charter: '包机'
    },
    DocumentCardForm: {
        Document: '文件',
        Country: '国家',
        'Document type': '文件类型',
        'Document number': '文件号',
        'Expiry date': '有效期',
        Save: '保存',
        Required: '必填',
        Remove: '删除'
    },
    Exchange: {
        'Change ticket': '机票换',
        'Successful payment': '付款成功',
        'Failed payment': '付款不成功',
        'Add new request': '创建新的预定',
        'Select passengers': '请选择乘客',
        Back: '往后',
        Next: '往下',
        'Select flights': '请选择航班',
        'Select new flight': '请选择新的航班',
        'Open calendar': '选择日期',
        'You’ve chosen': '您的选择',
        Clear: '清除',
        Search: '搜索',
        'Change flight': '更换飞行',
        Note: '请注意',
        'Exchange restrictions': '换机票限制',
        'The possibility and cost of changes will be calculated by the operator after sending the application. The answer will be sent to your E-mail and is also available on the order page.': NaN,
        'After submitting the request, the seats you have selected will be canceled.': '提交请求后，航班上所有选定的座位将被取消。',
        'Create request': '创建申请',
        Contacts: '创建申请',
        'Your name': '您姓名',
        Email: '电子邮箱',
        'I agree with the': '我与……同意',
        'Terms of Service': '提供服务规则',
        And: '和',
        'Privacy Policy': '隐私政策',
        'Your phone': '您的电话号码',
        'Your comment': '您的评论',
        'I agree to the Terms of Service and Privacy Policy': '我与规则同意',
        Request: '申请',
        Create: '已创建',
        'Your comments': '您的评论',
        'The cost of changes will be calculated by the operator after choosing the option and sending the request to the operator.': '换费用将在发送申请后由运营商计算',
        'Local time indicated': '当地时间',
        'Order must be paid by {{time}} on {{date}}': '订单必须在 {{日期}} {{时间}} 之前付款',
        InProcess: '在工作中',
        NeedConfirm: '等待确认',
        AwaitingPayment: '等待付款',
        Finished: '已完成',
        Rejected: '已取消',
        Refused: '已撤回',
        TimelimitExpired: '付款时间已过',
        'If you change your mind, or want to create a new request with different parameters, delete this request': '如果您改变主意或想要创建新请求，请删除当前请求',
        'Delete request': '删除请求',
        'The change request was deleted. Want to create a new request?': '当前请求将被删除。去创建一个新的吗？',
        Yes: '是',
        No: '不是',
        'Limited exchange time': '时限',
        'Offer valid until {{date}}': '优惠有效期至 {{日期}}',
        'Exchange penalty': '换机票的罚款和费用',
        Total: '一共',
        Required: '必填',
        'Please, read the rules of service and accept it': '请阅读并同意规则',
        'Please enter a valid email': '请输入正确的电子邮箱地址',
        'Please enter a valid phone': '请选择正确电话',
        'Whole flight': '整个飞行',
        'We couldn\'t find any flights for given dates': '我们没有找到所选日期的航班',
        'Please select another date or modify your search. If you wish to purchase a ticket for this particular date, please contact customer support.': NaN,
        for: '为',
        'Successfully create new booking': '新的订单已创建',
        'View new booking': '打开相关的订单',
        'Request for changes': '机票要还的申请',
        'Payment exchange': '支付',
        'Oops...': '哎呀……',
        '{{segmentId}}. {{depDate}}, {{airlineCode}}{{flightNumber}}. {{depIata}}({{depTime}}) -> {{arrIata}}({{arrTime}}). Тариф {{tariff}}': NaN,
        Refund: '回到退还',
        'All services issued on one electronic document will be canceled, even if you change itinerary partial. Money refund will be carried out by the airline in accordance with the terms of the Puplic Offer': NaN,
        'Select type of change': '请选择换机票的类型',
        'Voluntary change': '自愿地换机票',
        'Involuntary change': '被迫地换机票',
        Voluntary: '自愿',
        Involuntary: '被迫',
        'Back to order': '返回订单',
        'terms of tariff': '票价条件',
        'Select date for flight {{direction}}': '选择{{方向}} 航班 的日期',
        'If you wish to exchange your ticket, please cancel your flight check-in.': '如果您想换机票，请先取消您的航班值机。',
        'You can do it yourself by going to <a href=\'{{checkInURL}}\' target=\'_blank\'>Online check-in</a>.': NaN,
        'Either select unused ticket segments for exchange.': '或者选择未使用的机票的部分进行换。',
        'It is not possible to exchange the ticket on which the check-in was made for the flight': '您已办理登机手续的机票无法换。',
        'It is not possible to exchange a used ticket': '已使用的机票不可更换',
        'The ticket has been flown on, you cannot exchange the used ticket': '该机票已执行飞行；您不能换已使用的机票。',
        'Partially used ticket can be exchanged only in manual mode': '只能手动换部分使用的机票',
        'Contact the airline call-center to clarify the conditions of the exchange, as a part of the route was flown. Or select segments of an unused ticket for exchange.': NaN,
        'Part of the route was flown, part of the ticket is used. The application for exchange will be considered manually by the airline': NaN,
        'Select new date': '请选择新的日期',
        'Select date': '请选择日期'
    },
    FareConditions: {
        'Try again': '重试',
        'Internal error': '内部错误',
        Sheremetyevo: '谢列梅捷沃',
        Riga: '里加',
        Original: '原本',
        'Translate to russian*': '翻译成俄语*',
        'Fare conditions': '票价条件',
        'from 12 years': '从12岁起',
        'from 2 to 12 years': '2至12岁',
        'without seat': '无座位',
        'Fare code': '票价代码',
        'See more details on this page': '详细条件'
    },
    FareGroup: {
        Select: '选择',
        Unavailable: '不可用',
        Recommended: '热卖的',
        'Compare fare families': '票价比较',
        Choose: '选择',
        Rules: '规则',
        Taxes: '收费',
        'Not enough miles on your card': '您的卡上的英里不足'
    },
    FareLock: {
        'Fixation the cost of the air ticket': '确定机票费用',
        'Check your selection and pay fare lock': '检查您的选择并继续付款',
        'If you make the decision to fly on the selected date within the next {{hours}} {{plural}}, you will be able to pay the full amount of the booking at the fare you locked in. Please, be advised that Fare lock fee is non-refundable.': NaN,
        'No need to worry about the price increase or about the fact that all tickets will be sold out, now you can fix the ticket price for {{hours}} {{plural}}': NaN,
        hours_0: '小时',
        hours_1: '小时',
        hours_2: '小时',
        'Lock fare': '冻结',
        only: '总共',
        'Confirmed fare lock': '冻结票价'
    },
    FlightCard: {
        Flight: '航班',
        Duration: '在旅途中',
        'The flight operates': '该航班正在执行'
    },
    FlightInfo: {
        Details: '航班详情'
    },
    FlightInfoModalContent: {
        'You choose': '您选择了',
        'Compare fares': '费率比较',
        'Fare rules': '使用票价的规则',
        'Select your bundle': '请选择票价',
        Economy: '经济',
        Business: '商务',
        Premium: '高级的',
        'Flight info': '航班信息',
        'More details': '更多详情',
        'comfortable salon': '舒适的机舱',
        'access to wifi': '无线网络接入',
        'View full conditions': '费率比较',
        'hot and children\'s menu': '热食',
        'comfortable seating': '免费登机',
        'access to airport lounge': '选择座位是免费的',
        'meals are included in the price': '包含饮食',
        'premium fare': '高级的费率',
        'Back to flights': '返回航班',
        'Total duration': '总持续时间',
        'Scroll up': '上面',
        Selected: '已选择',
        'SelectedFareGroup_View full conditions': '票价比较',
        Back: '往后'
    },
    FlightPrice: {
        from: '从',
        'NO SEATS': '无座位',
        Economy: '经济',
        Light: '轻',
        Classic: '经典',
        Flex: '灵活',
        Business: '商务',
        Premium: '高级',
        'Select from': '从……选择',
        Select: '选择',
        'taxes and fees': '收费',
        'Subsidized flight': '补贴',
        'A subsidized fare requires all passengers on the booking to have benefits.': NaN,
        left: '剩了',
        left_0: '剩了',
        tickets: '机票',
        tickets_1: '机票',
        tickets_2: '机票',
        '{{count}} {{ticket}} {{left}}': '{{剩了}} {{计数}} {{机票}}',
        'Special conditions for flight delays and ticket refunds': '航班延误和退票的特殊条件',
        'Full conditions': '完全条件',
        'Transit tariff': '过境费率'
    },
    FlightsListControls: {
        Filter: '搜索',
        Nonstop: '不换成',
        'Flight Num': '飞行号',
        'Enter airline code or flight number': '请输入航空公司代码或航班号',
        Schedule: '时间表'
    },
    FlightSortFilter: {
        Sort: '分类',
        'Price Graph': '票价表'
    },
    Forms: {
        Search: '搜索',
        'My booking': '进入预订',
        Checkin: '在线登机',
        'Ticket number': '机票号',
        'Order number': '机票和订单号',
        'Last name': '乘客姓',
        Find: '寻找',
        Continue: '继续',
        'To check in for a Smartavia flight, please visit their official website': '如需查询Smartavia航班，请登录其官网'
    },
    FullscreenDialog: {
        Close: '关闭'
    },
    GuardedApolloProvider: {
        'Ops...': '哎呀……',
        'Unfortunately, something went wrong. Please try again or contact with us': '很抱歉，出了点问题。请稍后重试',
        Close: '关闭',
        'The service is temporarily unavailable. It is possible that the order is open on another device/browser or being handled by an airline specialist. Please try again later.': NaN
    },
    IncludedBaggage: {
        Included: '包含',
        there: '往',
        return: '返',
        'round trip': '所有的航班',
        Upgrade: '改善',
        included: '包含',
        'Whole route': '全路',
        'Choose for each flight': '每个航班单独',
        'Price per flight': '航班的价格',
        'Price for all flights': '整个航班的价格',
        Cancel: '取消',
        Confirm: '确定',
        Total: '一共',
        Clear: '清除',
        'Added for all flights': '对于整个路线',
        Equipment: '设备',
        'Need to bring sports, music or baby equipments?': '您需要携带运动器材、音乐设备或婴儿车吗？',
        'All flights': '所有航班',
        from: '从',
        total: '总共',
        Edit: '更改',
        Add: '添加',
        'Up to': '到'
    },
    IncludedOptions: {
        Included: '包含',
        'Baggage rules': '行李输送规定',
        'Upgrade cabin baggage': 'stb',
        'Get {{upgradedCabinBaggage}} of cabin baggage onboard, instead of {{includedCabinBaggage}}': '{{升级随身行李}}行李代替{{包含随身行李}}',
        pc: '个',
        kg: '公斤',
        with: '票价',
        Change: '更改',
        Upgrade: '添加'
    },
    InfoCard: {
        document: '文件',
        'Frequent Flyer programm': '会员卡',
        passport: '护照',
        'International passport': '出国护照',
        Number: '号',
        'Expiry date': '有效期',
        Delete: '删除',
        Edit: '编制',
        Add: '添加',
        'dd.mm.yyyy': '日.月.年'
    },
    InsurancePage: {
        'You lose protection and peace of mind in unforeseen situations.': '未选择保险',
        'Buy insurance': '收到保险金',
        'I assume all risks and Cancel insurance': '我拒绝支付保险金',
        'Insure your flight': '您必须选择保险',
        'To make your travels memorable only with pleasant excitement, take out an insurance policy that will protect you during your trip.': NaN,
        'For all passengers': '对于所有乘客',
        Remove: '删除',
        from: '从',
        'I want protect my trip.': '我希望在发生保险事件时收到付款',
        'I do not want to insure my life or luggage and assume all risks': NaN,
        'More details': '详情',
        'Choice of insurance': '保险选择',
        'Back to services': '返回服务',
        Cancel: '取消',
        Confirm: '确定',
        InsuranceUpsaleMainDescription: NaN,
        InsuranceUpsaleAdditionalDescription: NaN,
        'Most popular': '选择乘客',
        'Download police': '下载保险',
        Add: '添加'
    },
    Loader: {
        loading: '进行中'
    },
    Location: {
        Terminal: '航站楼',
        Details: '详情'
    },
    LocationAutocomplete: {
        'Recent searches': '您搜索',
        'show all directions': '显示所有',
        'Sorry, we do not fly': '没有航班',
        from: '从',
        to: '去往',
        'Nearby airports': '最近的机场',
        'Popular airports': '热门的方向',
        'Perhaps you are looking for': '也许您正在寻找',
        Stop_0: '换乘',
        Stop_1: '换乘',
        Stop_2: '换乘',
        From: '从哪',
        To: '去往',
        'Enter city or country': '请选择城市或国家',
        'Pick a country': '请选择国家',
        'Pick a city': '请选择城市'
    },
    LoginPage: {
        'Log in': '进入',
        Required: '必填',
        'Please enter a valid Email / Phone / Card number': '请输入电子邮箱/电话号码/卡号',
        'Email / Phone / Card number': '电子邮箱/电话号码/卡号',
        'or with you social network': '通过社交网络网络',
        'Please, read the rules of service and accept it': '要继续，请确认您同意处理个人数据',
        'Welcome!': '欢迎光临！',
        'You are logged in for the first time, please read the terms and conditions and give consent': NaN,
        'I agree to the': '我同意……',
        'Terms of Service and Privacy Policy': '处理我的个人数据',
        'An e-mail with code has been sent to you.': '包含代码的电子邮件已发送给您。',
        'Code sent to your phone.': '代码已发送到您的电话',
        'Please, check the code': '检查您输入的代码是否正确',
        'Didn\'t get the code?': '没收到代码？',
        'Resend it': '重新发送代码',
        'Welcome back,': '欢迎光临，',
        'Welcome back!': '欢迎光临！',
        'Enter code': '请输入确认码',
        'Verification code': '确认码',
        Continue: '继续',
        'Enter code_FFP': '请输入密码',
        'Verification code_FFP': '密码',
        Continue_FFP: '进入',
        'Enter code_Email': '请输入确认码',
        'Verification code_Email': '确认码',
        Continue_Email: '继续',
        'Enter code_Phone': '请输入确认码',
        'Verification code_Phone': '确认码',
        Continue_Phone: '继续',
        'If you don\'t have an account, it will be created automatically': '如果您没有帐户，系统会自动创建帐户',
        'Remember me': '记住我'
    },
    Loyalty: {
        'Log in to LightJet Club': '登录您的 Websky Club 个人帐户',
        'Forgot password?': '忘记密码？',
        Step: '步骤',
        'Enter your email': '请输入您的电子邮箱',
        Back: '往后',
        Continue: '继续',
        'Profile not found. Check spelling your e-mail address': '未找到个人资料。检查您的电子邮件是否正确',
        'Enter code': '请输入代码',
        'The verification code has been sent to the email': '确认码已发送您的电子邮箱',
        'Verification code': '钥匙',
        'You entered an incorrect code': '代码无效',
        'Password reset': '密码已更改',
        'A new password has been sent to your e-mail': '新的密码已发送到您电子邮件',
        'Back to login': '进入',
        Required: '必填',
        'Card number': '卡号',
        Password: '密码',
        'Not a member yet? Join for free!': '还没有卡吗？免费注册！',
        'Create account': '创建账户',
        'I have a card': '我已有卡',
        Create: '创建',
        'Data was sent to your email': '数据发送到电子邮件',
        'You successfully created account. Check your email to sign in': NaN,
        'Log in to account': '登录帐户',
        'Your status': '您的状态',
        'Ops...': '哎呀……',
        'Connect to loyalty program with your card': '将您的个人资料连接到会员活动',
        'Incorrect card number or password': '卡号或密码错误',
        'Please enter your card number': '必填',
        'Enter password': '必填',
        red: '红',
        silver: '白银',
        gold: '黄金',
        platinum: '白金',
        miles: '英里',
        'miles left for': '还到剩几英里',
        'Left for gold status': '达到黄金级别',
        'Left for silver status': '达到白银级别',
        'Left for platinum status': '达到白金级别',
        About: '详情',
        'Miles recovery': '恢复飞行',
        'Use miles': '使用英里',
        Promotions: '行动',
        'Promo-code': '促销代码',
        'E-voucher': '优惠',
        expiry: '到期',
        expired: '已到期了',
        Connect: '连接',
        'Get promo code': '获取促销代码',
        Get: '获取',
        Conditions: '条件',
        'How to get': '如何使用',
        'How to use': '如何使用',
        Active: '有效的',
        Expired: '已到期',
        Total: '总共',
        'Exit and add another card': '退出并添加另一张卡',
        'You sure?': '您确定？',
        'Delete card from your account?': '从您的帐户中删除卡？',
        No: '不是',
        Yes: '是',
        or: '或者',
        flights: '飞行',
        'Cannot create an account': '创建帐户时出错',
        'Registration of persons under the age of 14 in the loyalty program is prohibited': '仅当您年满 14 岁或以上时才能注册会员卡活动。',
        Close: '关闭',
        'out of': '从',
        'Left {{miles}} for {{level}} status': '距离 {{水平}} 状态还剩 {{英里}}',
        'Start saving now': '现在就开始节约吧！\n赚取英里并将其用于即将搭乘的航班。',
        Registration: '注册',
        Statuses: '状态',
        Earn: '赚取',
        'Earn miles': '赚取英里',
        Status: '状态',
        Use: '用',
        FAQ: '常见问题',
        'Please wait, we are updating information about your loyalty card. It make take a  few minutes...': NaN,
        'To continue registering in the loyalty system, fill out and confirm your email in your personal account.': NaN,
        'Fill email': '填写邮箱',
        'This auth type is already being used in another account': '该卡已在另一个帐户中使用',
        'The use of a loyalty card is prohibited': '禁止使用该卡'
    },
    LoyaltyBonus: {
        'LightJet Club': 'Websky Club',
        miles: '英里',
        'You will get for this flight': '您将收到此航班的',
        'Fly with us and get bonuses!': '与我们一起飞行并获得奖金！'
    },
    LoyaltyCardForm: {
        'Frequent Flyer': '会员卡',
        'Frequent Flyer Programm': '会员活动',
        'Document number': '号码',
        Save: '保存',
        Required: '必填'
    },
    Meal: {
        Add: '添加',
        Select: '选择',
        Clear: '清除',
        Done: '完毕',
        g: '斤',
        Allergens: '过敏物',
        Free: '免费',
        'Show description': '详情',
        Total: '总共',
        'Add for all': '为大家添加'
    },
    MealPage: {
        'Meals on board': '上机的饮食',
        'Feel the taste in flight': '感受飞行中的味道',
        Meal: '饮食'
    },
    MealService: {
        All: '所有饭菜',
        'Select menu': '请选择菜单',
        serving_0: '{{数量}}份',
        serving_1: '{{数量}}份',
        serving_2: '{{数量}}份',
        select: '选择',
        'Terms & conditions': '提供伙食条件',
        Unavailable: '无法使用',
        'Selected dishes': '已选择的饭菜'
    },
    MetasearchLoader: {
        'Checking seats availability': '检查可用性',
        'Just a few seconds...': '只需要几秒钟…',
        'Booking service is temporary unavailable': '暂时无法提供预订服务',
        'Please reload the page': '请您刷新页面',
        Reload: '更新'
    },
    MilesRecovery: {
        'Miles recovery': '恢复英里',
        'Please note that miles recovery is possible': '请注意，英里可以恢复：',
        MoreThanSixMonthsFlights: '仅适用于已经完成的航班',
        OnlyCompletedFlights: '适用于 6 个月前起飞的航班',
        'Ticket number': '机票号',
        Create: '创建预定',
        'Passenger surname': '乘客姓',
        Required: '必填',
        'Order not found': '订单未找到',
        'Enter your ticket number': '输入机票号',
        'Invalid date': '请输入正确的日期',
        'Flight date': '飞行日期',
        'Flight number': '航班号',
        'Pick a flight date': '选择航班日期'
    },
    MobileAuthForm: {
        'Sign in': '登入',
        'and fill in passengers info with a single click': '一键输入乘客数据',
        'Sign in with email': '使用电子邮件登录',
        'Continue as a guest': '以访客身份继续'
    },
    MobileSegmentInfo: {
        Nonstop: '直飞',
        'More info': '详情',
        stop: '换乘',
        in: '去往',
        Terminal: '航站楼'
    },
    MobileSelectedFare: {
        'You selected': '您选择了',
        'Compare fare families': '票价比较',
        'Fare conditions': '票价条件',
        Free: '免费',
        'Fare rules': '票价使用规则',
        'Baggage info': '行李信息'
    },
    MobileStepbar: {
        Flights: '飞行',
        Passengers: '乘客',
        'Onboard Services': '上机服务',
        Extras: '补充服务',
        Payment: '付款',
        Add: '添加',
        Total: '一共',
        Economy: '经济舱',
        Business: '商务舱',
        Adult: '成年人',
        Child: '儿童',
        Infant: '婴儿',
        'Infant with seat': '有座位的婴儿',
        Details: '详情',
        Change: '更改',
        ADT: '成年人',
        CLD: '儿童',
        INF: '婴儿',
        INS: '有座位的婴儿',
        M: '男',
        F: '女',
        Contact: '联系方式',
        'Not selected': '不选择',
        Free: '免费'
    },
    MobileSummaryFlight: {
        Returning: '返',
        Departing: '往',
        'taxes and fees': '关税'
    },
    MobileToolbar: {
        Back: '往后',
        Continue: '继续'
    },
    OrderCard: {
        'Booking ID': '预订代码',
        Open: '打开',
        'Security code': '访问代码',
        Passengers: '乘客',
        Details: '详情',
        Transactions: '交易',
        Download: '下载',
        Confirmed: '已支付',
        'The order must be paid today': '今天到……等待付款',
        'The order must be paid at': '到……等待付款',
        'Online check-in started': '在线登机开始了',
        'Tickets have been sent to {{email}}': '机票已发送到{{电子邮箱}}',
        'Send tickets to the email?': '机票要发送邮箱？',
        Booked: '已预订',
        Cancelled: '已取消',
        Total: '一共',
        'Changed your plans?': '改变计划了？',
        Exchange: '换',
        Refund: '退还',
        'Review:Waiting for payment': '等待付款',
        'Review:Expires at': '到期',
        'Review:Expires today at': '今天……点到期',
        'Account:Waiting for payment': '等待付款',
        'Account:expires at': '到期',
        'Account:expires today at': '今天……点到期',
        'Check-in': '在线登机',
        'Resend it': '重新发送',
        'Passengers details': '乘客信息',
        'Expired date': '有效期',
        Gender_male: '男',
        Gender_female: '女',
        'Additional information': '不错的信息',
        Accompanying: '陪同者',
        accompanies: '陪同',
        'Select an adult for accompany': '选择一名陪同成年人',
        'After selecting a new adult, please double-check all services. Please choose an adult who does not sit on the emergency seat row.': NaN,
        Confirm: '确定',
        'Change link to adult': '改变成年人的控制',
        'Oops...': '哎呀……',
        'An error occurred while executing the request. Please try again later or contact a customer support service.': NaN,
        OK: '好的',
        DeleteFromList: '从列表中删除',
        BookingRemoval: '删除预订',
        BookingRemovalMessage: '预订 {{orderLocator}} 将从列表中删除',
        Remove: '删除',
        Cancel: '取消'
    },
    OrderChangeHistoryModal: {
        History: '历史',
        'Loading...': '进行中……',
        'History is empty': '空的历史',
        Time: '日期/时间',
        Action: '行动'
    },
    OrderSummary: {
        Booking: '预订代码',
        'Security code': '访问代码',
        from: '从',
        to: '去往',
        Manage: '查看',
        'Currently there are no active bookings made through your private profile.': '您还没有进行任何预订。',
        'Search flight': '搜索航班',
        'I have a booking': '添加订单',
        'Check-in': '在线登机',
        Download: '下载',
        'For accessing booking page from another device': '为了从其他设备访问您的订单',
        'Waiting for payment': '需要支付',
        'expires at': '到',
        'expires today at': '今天到',
        'Time to complete booking has expired': '付款时间已过'
    },
    Page404: {
        'Destination unknown': '我们去往这里不飞行',
        'PAGE NOT FOUND': '页面未找到',
        'Take me home': '返回主页'
    },
    Passenger: {
        Adult: '成年人',
        Child: '儿童',
        Infant: '婴儿',
        'Infant with seat': '有座位的婴儿',
        phone: '电话',
        email: '电子邮箱',
        latinFirstName: '名字（拉丁）',
        latinLastName: '姓（拉丁）',
        latinMiddleName: '父名（拉丁）',
        docIssued: '签发日期',
        docIssuedBy: '签发文件的机关',
        city: '城市',
        country: '国家',
        firstName: '名字',
        lastName: '姓',
        middleName: '父名',
        birthDate: '出生日',
        gender: '性别',
        nationality: '国籍',
        docType: '文件类型',
        docNumber: '文件号',
        docExpiryDate: '有效期',
        loyaltyName: '会员卡',
        loyaltyNumber: '会员卡号',
        Passenger: '乘客',
        'Onboard services': '上机服务',
        'Latin symbols and digits': '拉丁字母和数字',
        'Enter date in dd.mm.yyyy format': '请输入正确的日期',
        'Incorrect date': '不正确的日期',
        'Date expired': '已过了有效期',
        Edit: '更改',
        Required: '必填',
        Passport: '护照',
        MilitaryIDCard: '海员证',
        DocOfPassportLusing: '临时身份证',
        'ReleaseCertificate ': '释放人员释放证明书',
        'ServicePassport ': '公务护照',
        'FederalParliamentDeputy ': '联邦委员会或国家杜马代表证书',
        DiplomaticPassport: '外交护照',
        InternationalPassportNotRU: '出国护照',
        InternationalPassportRU: '出国护照',
        InternationalPassport: '出国护照',
        NationalPassport: '国家护照',
        SeamanDocument: '海员证',
        SeniorDocument: '退休证',
        BirthRegDocument: '出生证',
        CertificateOfConvictedVacation: '身份证',
        ResidencePermit: '居留卡',
        'Only letters are allowed': '输入的字符无效',
        'Phone number of the same digits is not allowed': '请输入正确的电话号码',
        'Incorrect phone number': '请输入正确的电话号码',
        'Min 10 digits': '最少10位数字',
        'Max 18 digits': '最多18位数字',
        'Example +1234567890': '示例：+1234567890',
        'Only digits': '只有数字',
        '11 digits': '请输入11位数字',
        '10 digits': '请输入10位数字',
        '9 digits': '请输入9位数字',
        'No less then 5 digits': '不少于5位数字',
        'Incorrect email': '请您输入正确的电子邮箱地址',
        'Invalid date format': '请您输入正确的日期',
        'Example: IVШЯ123123': '示例：IVШЯ123123',
        'Example: IIIШЯ352007, 5465467': '示例：IIIШЯ352007, 5465467',
        'Example: AN0123456': '示例：AN0123456',
        'Cyrillic symbols are not allowed': '只有拉丁字母',
        identity: '乘客信息',
        citizenship: '文件',
        loyalty: '会员活动',
        contacts: '联系方式',
        'Don\'t have a card yet? Register now.': '不参加该活动？请报名',
        'Check if the input is correct': '检查您的输入是否正确',
        other: '其它',
        organizationName: '机关名称',
        personnelNumber: '考勤号',
        'A subsidized fare requires all passengers on the booking to have benefits.': NaN,
        'I have a loyalty card': '我有会员卡',
        'Don\'t have a card yet?': '不参加该活动？',
        'Register now': '请报名',
        Male: '男的',
        Female: '女的',
        Cancel: '取消',
        Confirm: '确定',
        'Please, check passenger data': '请检查乘客的信息',
        promoCode: '促销代码',
        'Passenger with disabilities': '残疾乘客',
        'Passengers with disabilities and other persons with reduced mobility and persons accompanying them shall board the aircraft before boarding': NaN,
        'Booking details of the accompanying passenger': '随行乘客预订详情',
        'Fill in the fields with the booking details of the accompanying passenger over 18 years of age. Please note that the flight dates, flight and class of service for both passengers must be the same.': NaN,
        'I confirm that the provided ticket number and the name of the accompanying passenger are correct and valid.': NaN,
        accompanyingPersonName: '随行乘客的全名',
        accompanyingPersonTicketNumber: '机票号',
        '13 digits': '请输入13位数字',
        'Select at least one service from the list below': '从列表中选择服务',
        linkedTraveller: '陪同者',
        'Popular countries': '热门国家',
        'Other countries': '所有国家'
    },
    Passengers: {
        Adults: '成年人',
        Teenagers: '青少年',
        Children: '儿童',
        'Latin symbols and digits': '拉丁字母和数字',
        Infants: '婴儿',
        'Infants with seat': '有座位的婴儿',
        'Young adult': '青年人',
        'Senior (men)': '退休者（男）',
        'Senior (female)': '退休者（女）',
        '12-23 years': '12-23岁',
        '12-18 years': '12-18岁',
        '55+ years': '55+岁',
        '60+ years': '60+岁',
        '12+ years': '12+岁',
        '2-11 years': '2-11岁',
        'Up to 2 years': '到2岁',
        'Add other categories': '其它类别',
        'Adults over twelve years old': '十二岁以上的成年人',
        'Young peoples from twelve to eighteen years old': '12岁至18岁的青少年',
        'Children from two to eleven years old': '二岁至十一岁的儿童',
        'Infants up to two years old, without seat': '两岁以下婴儿，无座位',
        'Infants up to two years old, with seat': '两岁以下婴儿，有座位',
        'Young peoples 12-23 years old': '十二岁至二十三岁的青少年',
        'Young peoples 12-18 years old': '12岁至18岁的青少年',
        'Senior men up 60 years': '55岁以上男性',
        'Senior women up 55 years': '55岁以上女性',
        'Please notice, you must receive your boarding passes at the airport': '请注意，您只能在机场获取登机牌。',
        'Senior men up 60 years, Senior women up 55 years': '养老金领取者：男的60岁以上，女的55岁以上',
        Senior: '退休者',
        'men 60+ female 55+': '男 60+ 女 55+'
    },
    PassengerSubsidy: {
        'Subsidies not available': '无法获得补贴',
        'A subsidized fare requires all passengers on the booking to have benefits.': NaN,
        'Edit passengers data': '返回编辑',
        'New search': '新的搜索',
        Attention: '请注意'
    },
    PassengerTypes: {
        Adult: '成年人',
        Adult_0: '成年人',
        Adult_1: '成年人',
        Adult_2: '成年人',
        ADT: '成年人',
        ADT_0: '成年人',
        ADT_1: '成年人',
        ADT_2: '成年人',
        AAT: '成年人',
        AAT_0: '成年人',
        AAT_1: '成年人',
        AAT_2: '成年人',
        Child: '儿童',
        Child_0: '儿童',
        Child_1: '儿童',
        Child_2: '儿童',
        CLD: '儿童',
        CLD_0: '儿童',
        CLD_1: '儿童',
        CLD_2: '儿童',
        CNN: '儿童',
        CNN_0: '儿童',
        CNN_1: '儿童',
        CNN_2: '儿童',
        CTN: '儿童',
        CTN_0: '儿童',
        CTN_1: '儿童',
        CTN_2: '儿童',
        CHD: '儿童',
        CHD_0: '儿童',
        CHD_1: '儿童',
        CHD_2: '儿童',
        Infant: '婴儿',
        Infant_0: '婴儿',
        Infant_1: '婴儿',
        Infant_2: '婴儿',
        INF: '婴儿',
        INF_0: '婴儿',
        INF_1: '婴儿',
        INF_2: '婴儿',
        'Infant with seat': '有座位的婴儿',
        'Infant with seat_0': '有座位的婴儿',
        'Infant with seat_1': '有座位的婴儿',
        'Infant with seat_2': '有座位的婴儿',
        INS: '有座位的婴儿',
        INS_0: '有座位的婴儿',
        INS_1: '有座位的婴儿',
        INS_2: '有座位的婴儿',
        YTH: '青年人',
        YTH_0: '青年人',
        YTH_1: '青年人',
        YTH_2: '青年人',
        SCF: '退休者（女）',
        SCF_0: '退休者（女）',
        SCF_1: '退休者（女）',
        SCF_2: '退休者（女）',
        SCM: '退休者（男）',
        SCM_0: '退休者（男）',
        SCM_1: '退休者（男）',
        SCM_2: '退休者（男）',
        РВТ: '有座位的婴儿',
        РВТ_0: '有座位的婴儿',
        РВТ_1: '有座位的婴儿',
        РВТ_2: '有座位的婴儿',
        SRC: '退休者',
        SRC_0: '退休者',
        SRC_1: '退休者',
        SRC_2: '退休者'
    },
    PaymentErrors: {
        'An error occurred while executing the request': '执行请求时发生错误。',
        'Please try again later or contact a customer support service': '再试一次或者联系客服。',
        Error: '错误',
        OK: '好的',
        Sirena2_already_processed: '订单已付款',
        Sirena2_access_denied: NaN,
        Sirena2_system_error: '无法连接支付系统。联系技术支持。',
        Sirena2_fatal_error: '无法连接支付系统。联系技术支持。',
        Sirena2_order_error: '无法处理订单。联系技术支持并报告错误码order_error。',
        Sirena2_invalid_order: '找不到订单。联系技术支持并报告错误代码invalid_order。',
        Sirena2_pricing_error: '无法处理订单。联系技术支持并报告错误码pricing_error。',
        Sirena2_booking_error: '无法处理订单。联系技术支持并报告错误代码 booking_error。',
        Sirena2_wrong_amount: '订单价格不正确。刷新页面或联系技术支持。',
        Sirena2_session_error: '无法启动付款流程。刷新页面。',
        Sirena2_max_attempts: '支付订单的尝试已结束。',
        Sirena2_repeat_needed: '无法继续付款。刷新页面。',
        Sirena2_unknown_tax_source: NaN,
        Sirena2_invalid_tax_data: '无法处理订单。联系技术支持并报告错误代码invalid_tax_data。',
        Sirena2_ambiguous_taxation_system: NaN,
        Sirena2_unknown_taxation_system: NaN,
        Sirena2_no_taxation_system: '无法处理订单。联系技术支持并报告错误代码 no_taxation_system。',
        Sirena2_shop_commission: '无法处理订单。联系技术支持并报告错误代码shop_commission。',
        Sirena2_shop_status: '订单已付款或已取消。',
        Sirena2_shop_common: NaN,
        Sirena2_shop_session: NaN,
        Sirena2_bank_network: NaN,
        Sirena2_bank_account: '卡详细信息无效。请检查您的详细信息，然后重试。',
        'Sirena2_bank_i-prohibition': '您将无法使用此卡付款。请联系您的银行或使用其他卡。',
        Sirena2_system_3dsecure: '无法识别卡详细信息。检查输入的信息并重试。',
        Sirena2_system_common: NaN,
        Sirena2_3dsecure_failed: '无法识别卡详细信息。检查输入的信息并重试。',
        Sirena2_user_timeout: '付款时间已过。刷新页面并重试。',
        Sirena2_user_cancel: '该操作已被取消。',
        Sirena2_user_duplicate: '无法处理付款。联系技术支持并报告错误代码user_duplicate。',
        Sirena2_shop_network: NaN,
        Sirena2_shop_price: '无法启动付款流程。联系技术支持并报告错误代码shop_price。',
        Sirena2_shop_invalid_fop: '此付款方式不可用。使用另一种方法。',
        Sirena2_shop_wrong_amount: '订单价格不正确。刷新页面或联系技术支持。',
        Sirena2_bank_funds: '账户内资金不足。充值您的余额或使用另一张卡。',
        Sirena2_bank_limit: '已超出卡交易限额。请联系您的银行或使用其他卡。',
        Sirena2_bank_common: NaN,
        Sirena2_system_in_progress: NaN,
        Sirena2_system_fraud: '针对可疑交易的防护已启动。'
    },
    PaymentForm: {
        Title_MoneyCarePro: '赊账买来',
        Header_MoneyCarePro: '赊账买来',
        Title_PayLate: '分期付款',
        Header_PayLate: '分期付款',
        Title_Idram: '通过 Idram 支付',
        Header_Idram: '通过 Idram 支付',
        Payment: '付款',
        Total: '一共',
        'Choose a convenient payment method': '选择方便的付款方式',
        'Credit or debit card': '网上刷卡',
        Service: '机票和服务',
        Charge: '费用及佣金',
        'Pay by credit or debit card': '网上刷卡支付',
        'Other methods': '其他付款方式',
        'In the next step you will be taken to a Google Pay page.': '在下一步中，您将被重定向到 Google Pay 付款页面。',
        'Test payment': '测试付款',
        'Edit order': '更改订单',
        Instalment: '分期付款',
        Aeroexpress: '航空快运',
        Airticket: '机票',
        Baggage: '行李',
        Insurance: '保险',
        BusinessLounge: '商务休息室',
        Businesslounge: '商务休息室',
        Vip: '贵客服务',
        Meals: '饮食',
        'Please, click «Edit Order» after successful payment in Kaspi.kz APP to open your order.': '在 Kaspi.kz 应用程序中成功付款后，点击“更改订单”按钮返回订单。',
        Seats: '座位',
        'Failed to get billing code': '无法接收付款代码',
        Invoicebox: '发票箱',
        FPS: '快速支付系统',
        SberPay: 'SberPay',
        'Payment code': '付款代码',
        Pay: '支付',
        'Problems with payment?': '付款有问题吗？',
        'Payment by QIWI': '通过 QIWI 支付',
        'Payment problems hint': NaN,
        'Please, click «Edit Order» after successful payment in QIWI app to open your order.': '在QIWI应用程序中成功付款后，点击“编辑订单”按钮返回订单。',
        'Payment by this method is temporarily unavailable, please try refreshing the page or try again later.': NaN,
        'The payment with this method is unavailable.': '无法通过此方式付款。'
    },
    PaymentProvider: {
        PayLate: 'PayLate',
        MoneyCarePro: 'MoneyCarePro'
    },
    PaymentResult: {
        'Payment successfully complete': '付款成功',
        'View booking': '转到订单',
        'Payment has failed': '付款失败',
        'Try again': '重试'
    },
    PaymentTimeLimit: {
        'Booking must be completed': '必须下订单',
        'Booking must be paid': '订单必须付款',
        before: '到',
        'today before': '今天到',
        'Sorry,': '很抱歉',
        'time to complete booking has expired': '您下订单的时间已过',
        'Repeat search': '重复搜索',
        'Cancel order': '取消订单'
    },
    PriceGraph: {
        'Outbound flight': '请选择去往的航班',
        'Return flight': '选择回程航班',
        min: '最少',
        max: '最多',
        'Select month': '请选择月份',
        Select: '选择',
        from: '从',
        'Approximate prices for 1 person for 1 direction': '所示价格为 1 人单程价格',
        'Price for 1 direction': '单程价格',
        'Show flights': '显示航班'
    },
    PriceInMiles: {
        miles_1: '英里',
        miles_2: '英里',
        miles_3: '英里'
    },
    PriceLock: {
        'Lock this price! We will wait for you': '冻结价格！我们会等您',
        'Lock it for 72 hours from just': '价格冻结仅72小时',
        'per passenger and leg': '乘客的',
        'I want to lock the price for this flight': '我想冻结该航班的价格'
    },
    PromoCode: {
        'Promo code not applied': '促销代码未应用',
        reset: '删除',
        'Do you have a promo code?': '您有促销代码？',
        'Promo code applied': '促销代码已应用',
        'Enter a promo code': '请输入促销代码',
        'Promo code not applied for this flights': '促销代码不适用于该航班',
        'Promo code': '促销代码',
        Required: '必填',
        'I confirm that I am an employee of Rostech. Otherwise, the Carrier has the right to cancel the ticket.': NaN,
        'The number of characters must not exceed {{number}}': '字符数不得超过 {{数量}}'
    },
    PromoLoader: {
        'One moment please': '请一秒钟',
        'Discover new horizons': '开拓新视野',
        'International flights from Kazakhstan': '从哈萨克斯坦出发的国际航班',
        Istanbul: '伊斯坦布尔',
        Tbilisi: '第比里斯',
        Moscow: '莫斯科',
        Xiang: '湖北',
        'Comfort on board': '机上舒适',
        Meal: '饮食',
        'Wi-Fi Connect': '飞机上有网络',
        Fleet: '我们的机队',
        Seats: '座位',
        'It is safe to fly': '安全飞行',
        '2,000 miles giveaway!': '我们赠送2000英里！',
        'Choose your seats when booking': '预订时选择座位',
        'Save up to 25% when buying extra baggage right away': '预订时购买额外行李可节省高达 25%',
        'We want to make sure your travel experience is as fun and smooth as possible also when travelling as a family. All the way from planning your trip to arriving at the airport and enjoying your time in the air, we pay special attention to your comfort.': NaN,
        'Every Nordwind flight is an opportunity to relax and replenish. On our local flights by Russia and flights to CIS, we serve a diverse selection of food, snacks and beverages, tailored to the time and length of your flight.': NaN,
        'The Nordwind fleet consists of over 30 aircraft. Our fleet is one the most modern in country, and thanks to it can offer you even better travel comfort on our flights.': NaN,
        'Every flight is an opportunity to relax and replenish. On our local flights by Russia and flights to CIS, we serve a diverse selection of food, snacks and beverages, tailored to the time and length of your flight.': NaN,
        'The fleet consists of over 30 aircraft. Our fleet is one the most modern in country, and thanks to it can offer you even better travel comfort on our flights.': NaN,
        'Flying is not a reason to refuse to communicate with family and colleagues. Wi-Fi internet on board is a unique portal to the world of entertainment and information in flight.': NaN,
        'Want to sit together or love the window seat? Do not leave the choice of place to chance.': '您想坐在一起还是喜欢靠窗的座位？不要随意选择座位。',
        'From locking middle seats to completely disinfecting our aircraft, we do our best to keep you safe.': NaN,
        'To thank you for your loyalty and support, we grant our club members 2,000 miles!': NaN,
        'Don’t let chance affect your comfort and convenience.': '不要把舒适和便利留给机会。'
    },
    PromoMessage: {
        'Buy now - pay later': '现在飞行 - 稍后付款！现金返还 15%',
        'Learn more': '详情',
        'You can buy any ticket with credit for 12 months, without an initial payment, with a grace period of 30 days.': NaN,
        'Only Citizens of the Republic of Uzbekistan and Tajikistan are allowed on the flight': '仅允许乌兹别克斯坦和塔吉克斯坦共和国公民搭乘航班'
    },
    QuickSearchForm: {
        'Modify search': '更改搜索',
        Adult_0: '成年人',
        Adult_1: '成年人',
        Adult_2: '成年人',
        Child_0: '儿童',
        Child_1: '儿童',
        Child_2: '儿童',
        Infant_0: '婴儿',
        Infant_1: '婴儿',
        Infant_3: '婴儿',
        ADT_0: '成年人',
        ADT_1: '成年人',
        ADT_2: '成年人',
        passenger_0: '乘客',
        passenger_1: '乘客',
        passenger_2: '乘客',
        МЛА_0: '乘客（青年人）',
        МЛА_1: '乘客（青年人）',
        МЛА_2: '乘客（青年人）',
        SCM_0: '乘客（退休者）',
        SCM_1: '乘客（退休者）',
        SCM_2: '乘客（退休者）',
        SCF_0: '乘客（退休者）',
        SCF_1: '乘客（退休者）',
        SCF_2: '乘客（退休者）',
        PDA_0: '青少年',
        PDA_1: '青少年',
        PDA_2: '青少年',
        YTH_0: '青年人',
        YTH_1: '青年人',
        YTH_2: '青年人',
        SRC_0: '退休者',
        SRC_1: '退休者',
        SRC_2: '退休者',
        РВТ_0: '有座位的婴儿'
    },
    RecaptchaPage: {
        'You\'re not a bot, are you?': '您不是机器人？'
    },
    RecommendedBadge: {
        'Best choice': '最好的选择'
    },
    Refund: {
        'I agree with the': '我与……同意',
        'Terms of Service': '提供服务规则',
        And: '和',
        'Privacy Policy': '隐私政策',
        New: '新的预定',
        AllDataSaved: '数据已保存',
        Adopted: '预定已接受',
        Calculated: '退款金额已计算',
        ClaimReceived: '预定已发送',
        AwaitingPayment: '预计退款',
        TimelimitExpired: '确定时间过失了',
        Finished: '已完成',
        Cancelled: '已取消',
        Rejected: '已拒绝',
        NeedConfirm: '等待确认',
        InProcess: '进行中',
        'Add new request': '创建新的预定',
        Refused: '已被用户拒绝',
        FlightCancellationOrDelay: '机票上注明的航班取消或延误；',
        Rerouting: '改变航线（未考虑恶劣天气条件和不可抗力）；',
        FlightOperationOutOfSchedule: '执行不定期航班；',
        SeatUnavailability: '机票上注明的航班和日期缺少乘客座位；',
        EnduringSecurityScreening: NaN,
        FailedEnsureConnectionOfFlights: NaN,
        Illness: NaN,
        FailedToProvideServices: '如果承运人未按照机票上注明的舱位等级向旅客提供服务；',
        FailedToIssueTicketAppropriately: '如果承运人或其授权代理人未能正确出机票；',
        OtherCases: '由于承运人的过错而未能为乘客提供运输的其他情况；',
        MedicalCertificate: '疾病证明书',
        Request: '预定',
        Create: '已创建',
        'Your comments': '您的评论',
        'Refund amount': '转到退还',
        'Ticket price': '预订价格',
        'FareExchange penalty': '交换罚金',
        Continue: '继续',
        Back: '往后',
        'Select passengers': '请选择乘客',
        'Choose the reason for refund': '请选择退还原因',
        'Involuntary refund': '被迫退还',
        'Voluntary refund': '自愿退还',
        'my plans have changed': '我的计划改变了',
        'Attach the required files': '附上所需的文件',
        'File format: jpg, pdf. File size: no more than 5MB': '文件格式：jpg、png、pdf、doc、docx。文件大小：不超过5兆字节',
        'Your name': '您的姓名',
        'Your email': '您的电子邮箱',
        'Your phone': '您的电话号码',
        'I agree to the': '我与……同意',
        'Terms of Service and Privacy Policy': '服务条款和隐私政策',
        'Create request': '创建预定',
        'Only letters': '只有字母',
        Required: '必填',
        'Wrong email': '不正确的电子邮箱',
        'Wrong phone': '不正确的电话号码',
        For: '为',
        Files: '文档',
        'Refund ticket': '退机票',
        'Refund restrictions': '退还限制',
        Note: '请注意',
        'The possibility and amount of refund will be calculated by the operator after sending the application. The answer will be sent to your E-mail and is also available on the order page.': NaN,
        'After submitting the request, the seats you have selected will be canceled.': '您提交请求以后，您选择的座位将被取消。',
        'The money will be returned to the card used to pay for the ticket within 15 days': '这笔钱将在 15 天内退回到用于支付机票的卡中。',
        Reject: '取消',
        Confirm: '确认',
        'The request is accepted for processing. The answer will be sent to your email and also available on the order page.': NaN,
        'The request was deleted. Want to create a new request?': '该预定将被删除。您想创建一个新的预定吗？',
        'Successful refund. Confirmation and detalization have been sent to your e-mail': '退还成功。详情将发送至您的电子邮箱',
        Ok: '好的',
        Yes: '是',
        No: '不是',
        'Request for refund': '退还请求',
        'Successfully refund': '退还成功',
        'Delete request': '删除申请',
        FlightNumberChange: '更改航班号',
        FlightRetimed: '航班恢复',
        FlightCancelled: '航班取消',
        PermanentWithdrawalOfService: '永久终止服务',
        LaborDispute: '劳资纠纷',
        Bereavement: '巨大的损失',
        UnableToObtainNecessaryDocumentation: '无法获取所需的文档',
        Strike: '罢工',
        Weather: '天气条件',
        NaturalDisaster: '自然灾害',
        UnacceptableReaccommodation: '不可接受的配置',
        Refund_Passengers: '继续',
        Refund_Reason: '继续',
        Refund_CreateTicket: '继续',
        'terms of tariff': '票价条件',
        'Back to order': '返回订单',
        'To make an exchange please contact a customer support service': '要退还，您必须联系支持中心',
        'Oops...': '哎呀',
        'View related booking': '打开相关订单',
        'It is not possible to refund a ticket that has been checked-in for a flight': '已办理登机手续的机票不可退还。',
        'If you wish to make a refund, please cancel your flight check-in.': '如果您想退还，请先取消您的航班值机。',
        'You can do it yourself by going to <a href=\'{{link}}\' target=\'_blank\'>Online Registration section</a>.': NaN,
        'It is not possible to refund a used ticket': '使用过的机票不可退还',
        'The ticket has been flown on, you cannot return a used ticket.': '该机票已实现飞行，已使用的机票不可退还。'
    },
    RegisteredPassenger: {
        Economy: '经济',
        Business: '商务',
        First: '头等',
        'Download boarding pass': '下载登机牌',
        Download: '下载',
        CarryOn: '手提包',
        CheckedBaggage: '行李',
        SportingEquipment: '运动器材',
        'Select flight for download': '请选择飞行',
        'Select flight': '请选择飞行',
        Seats: '座位',
        Meal: '饮食',
        'Cancel check-in': '取消登机',
        'Retry check-in': '重复登机',
        Yes: '是',
        No: '不是',
        'You are going to cancel online check-in for passenger {{passenger}}. Are you sure about this?': '您确定要取消乘客 {{乘客}} 的在线值机吗？',
        'Send to email': '通过电子邮件发送邮件',
        Send: '发送',
        'Registration available only at airport': '只能在机场办理登机手续',
        'You must receive the boarding pass at the airport on the registration desk.': '您必须在机场值机柜台领取登机牌。',
        'Send all documents to email': '通过电子邮件发送所有文件',
        Cancel: '取消',
        'Required field': '必填',
        'Please enter a valid email': '请输入有效的电子邮件地址',
        Email: '电子邮箱地址',
        'Please notice that online check-in will also be cancelled for the following passengers': '以下乘客的登机手续也将被取消：',
        'Select segments to cancel': '选择要取消的航班'
    },
    ResendTicketsForm: {
        Email: '电子邮箱地址',
        Send: '发送',
        Required: '必填写',
        'Resend booking': '发送机票',
        'Failed to send tickets to the specified mail, try again later': '发送机票至指定邮箱失败，请重试',
        'Please enter a valid email': '请检查输入的电子邮件地址。'
    },
    Results: {
        'Select flight': '请选择航班',
        'Select flight to on': '请选择去往 {{城市}}  {{日期}}的航班',
        'Monthly price': '一个月的',
        'Weekly price': '一周的',
        Back: '往后',
        Sorting: '分类',
        Filters: '过滤',
        'Sort by': '分类',
        Arrival: '抵达时间',
        Departure: '起飞',
        'Best flight': '最好的飞行',
        Price: '价格',
        'Flight time': '行程时间',
        Returning: '返',
        Departing: '往',
        'You\'ve chosen': '已选择的飞行',
        'Depart on': '起飞',
        'Departure time': '起飞时间',
        'save up to': '节约吧',
        'Sold out': '机票卖完',
        'Show flights': '显示选项',
        'Unfortunately, all seats on selected flight are already sold out.': '很抱歉，您选择的航班的所有机票均已售完。',
        'We can offer you another flights for the same dates.': '我们可以为您提供同一日期的其它航班。',
        'Other dates': '其它日期',
        'Total price': '一共',
        'Want to continue booking on your computer?': '想继续在电脑上预订吗？',
        'Continue booking': '继续预订',
        'Only direct flights': '只有直飞',
        'Only business class': '只有商务舱',
        Ok: '好的',
        'Show price diagram': '价格表',
        'Redeem miles': '使用英里',
        Card: '卡',
        'Change card': '换卡',
        'Pay by money': '用钱支付',
        'You sure?': '您确定？',
        'Delete card from your account?': '从您的帐户中删除一张卡？',
        No: '不是',
        Yes: '是',
        Nonstop: '不换车',
        'Business Class': '商务舱',
        Attention: '请注意',
        'You are buying a separate ticket for a child. For children under 12 years old unaccompanied traveling is not allowed. On the passenger data page select who will fly with the child: enter the ticket number and the name of the accompanying person over 18 years old. Please note: both passengers must be of the same class of service.': NaN,
        'If you still have questions, please contact the Contact Center.': '如果您有任何疑问，请联系联系中心。'
    },
    RetrieveBooking: {
        'Booking ID': '机票号码、订单号码或预订代码',
        'Security code': '访问代码或乘客的姓',
        'Retrieve booking': '进入预订',
        Open: '搜索',
        'Enter ticket number': '请输入机票号',
        'Enter passenger last name': '请输入乘客的姓',
        'Enter your book code or ticket number': '请输入预订代码或机票号',
        'Incorrect value': '不正确的姓',
        'Ticket number': '机票号',
        'Required field': '必填',
        'Please enter ticket number for passenger you would like to check-in': '请输入您要办理登机手续的乘客的机票号码',
        'Order not found': '订单未找到',
        'Passenger not found': '乘客未找到',
        'You can find security code in your booking confirmation email': '访问代码可以在您的预订确认电子邮件中找到',
        'The order is being paid at the moment, please try again later': '目前正在处理您的订单付款，请稍后重试',
        'Enter your ticket number': '请输入机票号',
        'Booking not found. Please, check the information entered or use your e-ticket number for identification': NaN,
        'Online check-in is not available. Check-in only at the airport': '不提供在线登机。仅在机场办理登机手续'
    },
    Schedule: {
        'Where are you flying from': '你从哪里飞来？',
        'Where are you flying to': '您从哪里飞往',
        'Is operated by': '完成航行',
        'We did not find flights to the selected cities': '我们没有找到飞往所选城市的航班',
        'Please select another city or change your search. If you would like to purchase a ticket for a specific city, please contact customer service.': NaN
    },
    ScheduleForm: {
        Schedule: '时间表',
        From: '从哪',
        To: '去往',
        'From, enter city or country': '从哪，请输入城市或国家',
        'To, enter city or country': '去往，请输入城市或国家',
        'Please select {{type}} airport': '请选择机场{{型式}}',
        'Please select different airports': '请选择不同的 机场',
        arrival: '抵达的',
        departure: '起飞的'
    },
    SearchForm: {
        'Enter promo code': '输入促销代码',
        'Redeem miles': '花英里',
        'Set simple route': '设置简易行程',
        'Set multi city route': '制定复杂的行程',
        From: '从哪',
        To: '去往',
        'Please select {{type}} airport': '请选择机场{{型式}}',
        'Please select different airports': '请选择不同的机场',
        Search: '搜索',
        'Please select departure date': '请选择起飞日期',
        Outbound: '往',
        Return: '返',
        Dates: '起飞日期',
        arrival: '抵达',
        departure: '起飞',
        'From, enter city or country': '城市或国家',
        'To, enter city or country': '城市或国家',
        passenger_0: '乘客',
        passenger_1: '乘客',
        passenger_2: '乘客',
        Passengers: '乘客',
        'Other categories': '其它类别',
        Clear: '清除',
        Done: '完毕',
        'Enter name or code of category': '请输入类别代码或名称',
        'Pay by miles': '我想使用英里',
        'Please select passengers': '请选择乘客',
        'Offer hotels': '推荐酒店',
        'Departure airport or city': '出发的城市',
        'Arrival airport or city': '抵达的城市',
        Resident: '居民',
        ResidentInfo: '<span>欧元 (EUR)、美元 (USD)、卢布 (RUB)</span> 对外国公民开放'
    },
    SearchFormDatepicker: {
        Oops: '哎呀',
        'Something went wrong, please check your internet connection or come back later': '出了点问题，请检查您的互联网连接或稍后重试',
        Ok: '好的',
        'Without back date': '回程票不要',
        Clear: '重置',
        Done: '完毕',
        'One way': '完毕',
        'Price matrix': '比较价格',
        Calendar: '日历',
        'Price graph': '一月价格',
        Outbound: '去',
        Return: '往',
        'Approximate prices for 1 person for 1 direction': '所示价格为 1 人单程价格'
    },
    SeatMap: {
        'Less than 24 hours left before the flight': '距离航班起飞还有不到 24 小时。',
        'You can select seats using the online registration service after issuing tickets': NaN,
        Restriction_ElderlyPerson: '65岁以上旅客',
        Restriction_Unaccompanied: '无成人陪伴儿童',
        Restriction_SeriouslyIllPeople: '残疾人',
        Restriction_DoNotSpeakTheLanguage: '不讲机组人员语言的乘客',
        Restriction_EscortedChildren: '无成人陪伴儿童',
        Restriction_Infant: '携带婴儿的乘客',
        Restriction_NeedOxygenEquipment: '需要特殊氧气设备的乘客',
        Restriction_PassengersOnStretchers: '行动不便的乘客和残疾人',
        Restriction_PassengersUnder18: '18岁以下人',
        Restriction_PregnantWomen: '孕妇',
        Restriction_RequiringAccompany: '需要陪同的乘客',
        Restriction_VisuallyOrHearingImpaired: '有视力或听力障碍的乘客',
        Restriction_WithAnimals: '机舱内携带宠物的乘客',
        'This seat is forbidden for': '该座位禁止选择',
        select: '选择',
        'not available': '不可用的',
        'Unfortunately, seat selection on this flight is not available.': '很抱歉，此航班不提供座位选择服务。',
        'Next flight': '下次飞行',
        'Reserved seat': '有人占着的座位',
        '90% on the left side': '阳光在左边',
        '90% on the right side': '阳光在右边',
        'Business class': '商务舱',
        'Economy class': '经济舱',
        'First class': '头等舱',
        Business: '商务舱',
        Economy: '经济舱',
        First: '头等舱',
        Exit: '出口',
        seat_0: '{{数量}} 座位',
        seat_1: '{{数量}} 座位',
        seat_2: '{{数量}} 座位',
        Seats: '座位',
        Adult: '成年人',
        Child: '儿童',
        Infant: '婴儿',
        'Infant with seat': '有座位的婴儿',
        Free: '免费',
        'MAIN DECK': '主甲板',
        'You can choose your preferred seats.': '选择最舒适的座位',
        'UPPER DECK': '上层甲板',
        'Seat details': '座位信息',
        Select: '选择',
        'Select seat': '选择座位',
        'Select your seats': '请选择座位',
        'Select passenger': '请选择乘客',
        Cancel: '取消',
        'Take this seat': '选择座位',
        'Change seat': '换座位',
        Recommend: '我们推荐',
        'Fly together!': '一起飞吧！',
        'Show recommended seats': '推荐的座位',
        From: '从',
        Total: '一共',
        'Choose your seat': '请选择座位',
        'Choose myself': '自己选择',
        Confirm: '确定',
        'Recommended seats': '我们推荐',
        Comfort: '舒适的座位',
        Preferred: '最好的座位',
        Standart: '标准的座位',
        Clear: '清除',
        'Clear all': '清除所有',
        'Passenger, {{type}}': '乘客， {{种类}}',
        'Next Flight': '下次飞行',
        Back: '往后',
        from: '从',
        Done: '完毕',
        'The top ones get the top seats. Be on top!': '最好的座位去第一个。 先抢吧！',
        'If you don\'t select a seat, we\'ll automatically allocate your seats when you check-in.': '我们建议您立即选择座位，最好的座位优先！',
        'Pay attention!': '请注意！',
        'The airline reserves the right to replace the aircraft.': '航空公司保留更换飞机的权利。',
        'In the event of an aircraft replacement, passengers who have paid for the “seat selection” service will be provided with seats on the plane that are similar in price and comfort, while maintaining the following parameters': NaN,
        '- Cost of services;': '服务价格；',
        '- Location of the place relative to the window / aisle;': '—该座位相对于窗户/通道的位置；',
        '- Joint seating, if one was originally selected;': '— 联合座位（如果最初选择的是联合座位）；',
        '- Placement in the front row of the cabin, if one was originally selected.': '— 放置在机舱的第一排，如果最初选择的话。',
        'Terms & conditions': '服务更多信息',
        Continue: '继续',
        Note: '重要',
        SeatMap_Comfort: '舒适的',
        SeatMap_Preferred: '最好的',
        SeatMap_Standart: '标准的',
        SeatMap_Standard: '标准的',
        'Selected seat is not available': '所选座位不可用',
        'The passenger can select a suitable seat marked for passengers with pets on the map. The airline has the right to transfer a passenger with an animal.': NaN,
        'This seat is allowed for': '此座位允许选择为',
        Permissions_WithAnimals: '携带宠物的乘客',
        'Free seat': '免费',
        'Free seats': '免费的座位',
        'Information for the place': '座位信息',
        'Chair back does not recline': '座椅后不仰',
        'Double seat': '双人座位',
        'An extra seat can also be booked for the safe transport of valuable and fragile items such as antiques, artifacts, family heirlooms or oversized musical instruments.': NaN,
        'When choosing the «Double Seat» service, the seats previously added by the passenger will be deleted': '选择“双人座位”服务时，乘客之前添加的座位将被删除',
        'When you select this service, it will be automatically applied to all flights of your route': '当您选择此服务时，它将自动应用于您行程中的所有航班。',
        'When the «Double Seat» service is disabled, the seats added by the passenger earlier will be deleted': '如果“双人座位”服务被停用，乘客之前添加的座位将被删除',
        'Seat selection': '选择座位',
        'Children under 2 years of age fly in the same seat with an adult': '2 岁以下儿童与成年人乘坐同一座位',
        'Window seat': '靠窗的座位',
        'Seat with no window': '无窗的座位',
        'The service applies to all flights': '该服务适用于所有航班',
        'Selected seats will be deleted': '已选择的座位将被删除。',
        'Seat next to the partition': '靠隔墙的座位',
        'Comfort seat': '舒服的座位'
    },
    SecureAccess: {
        'Enter your access confirmation number. For security reasons, it was sent to the email you provided when creating the order.': NaN,
        'Ticket / order number from e-mail': '信件中的机票或订单号',
        'Add booking': '添加预订',
        Continue: '继续',
        'The entered code does not match the code from the email': '输入的代码与电子邮件中的代码不匹配'
    },
    Segment: {
        Departure: '起飞',
        Flight: '航班号',
        Terminal: '航站楼',
        Gate: '登机口',
        Boarding: '上飞机',
        'Fare rules': '费率规则',
        'Airport check-in': '在机场办理登机手续',
        'Baggage info': '行李信息',
        Economy: '经济',
        Business: '商务'
    },
    SelectedPassenger: {
        Birthday: '出生日期',
        Gender: '性别',
        Male: '男',
        Female: '女',
        Citizenship: '国籍',
        Passport: '护照',
        MilitaryIDCard: '军人证',
        DocOfPassportLusing: '临时身份证',
        DiplomaticPassport: '外交护照',
        InternationalPassportNotRU: '出国护照',
        InternationalPassportRU: '出国护照',
        InternationalPassport: '出国护照',
        NationalPassport: '国家护照',
        SeamanDocument: '海员证',
        SeniorDocument: '退休证',
        BirthRegDocument: '出生证',
        CertificateOfConvictedVacation: '身份证',
        ResidencePermit: '居留卡',
        Ticket: '机票号',
        'Add frequent flyer number for': '为...添加会员卡号',
        Add: '添加',
        'Frequent Flyer Number': '会员卡号',
        'Add frequent flyer': '添加会员卡',
        'Frequent flyer': '会员卡'
    },
    SelectSegment: {
        Confirm: '确定',
        'Check-in for flights': '航班的登机',
        Flight: '航班',
        Select: '选择',
        GetTalon: '登机完成。获取登机牌',
        GoToCheckin: '登机现已开始。前往登机'
    },
    Service: {
        from: '从',
        Total: '一共',
        Close: '关闭',
        free: '免费',
        Done: '完毕',
        Clear: '清除'
    },
    ServiceRecommendation: {
        'To save your time, we have selected for you the best available seats on the plane.': '为了节省您的时间，我们选择了飞机上最舒适的座位。',
        'Seats recommendation': '推荐的座位',
        Change: '自己选',
        Confirm: '确认',
        Total: '一共',
        Flight: '航班',
        Seats: '座位',
        Price: '价格',
        FREE: '免费',
        Details: '细节',
        Allergens: '致敏原',
        'OK, thank you': '明白，谢谢',
        'We offer you this meal': '所提供的菜肴已提供',
        'for FREE': '免费',
        'on all flight directions': '在所有航线上',
        'on flight directions': '在...方向',
        'for all passengers': '对于所有乘客',
        'for passengers': '对于乘客'
    },
    ServiceWithSegments: {
        'Next flight': '下一个航班'
    },
    SocialAuth: {
        'Social networks accounts': '社交网络上的帐户',
        'Quick login to your account': '快速登录您的帐户',
        'Connect another social media account': '连接另一个社交网络上的帐户'
    },
    Stepbar: {
        Flights: '飞行',
        Seats: '座位',
        Baggage: '行李',
        Insurance: '保险',
        Meal: '饮食',
        Passengers: '乘客',
        Extras: '服务',
        Payment: '付款',
        Order: '订单',
        'You can {{payload}}': '您可以{{有效载荷}}',
        'add services': '订单中添加服务',
        'change dates': '更改起飞日期'
    },
    Subscriptions: {
        'You have a subscription': '您有订阅',
        'There are users in the order who are not covered by your subscription. The price of their tickets is considered without discount.': NaN,
        'Members without Subscription': '没有订阅的乘客',
        Passenger: '乘客',
        Without: '没有订阅',
        With: '有订阅',
        'Continue, I agree with this price': '继续，我同意价格',
        'Add passengers to subscription': '将乘客添加到您的订阅中',
        'Reselect services and seats': '重新选择服务和地点',
        'Do you have regular flights?': '你经常坐飞机吗？',
        'Cut costs with a subscription': '订阅将降低成本',
        'View Offers': '查看意见',
        'Seat selection and other subscription services at a reasonable price': '选座和其他实惠的订阅服务',
        'View options': '查看种类',
        'Subscription member': '包含在订阅中',
        'Discounts apply to the user': '折扣适用于用户',
        formatDate: '日.月.年',
        Subscribe: '订阅',
        'WebSky \nSubscription': '订阅WebSky',
        'Your account details': '您个人帐户的信息',
        Plan: '计划',
        'Date of end': '结束日期',
        'Your members': '您的成员',
        from: '其中',
        Member: '成员',
        You: '您',
        Remove: '删除',
        'Add member': '添加成员',
        'You can add more': '您可以添加另一个 {{数量}} {{成员}}',
        'Added max number of members': '添加的最大成员数量',
        member_0: '成员',
        member_1: '成员',
        Payments: '付款',
        'The next payment for your subscription will be {{date}}': '预计下次订阅付款 {{日期}}',
        'Cancel auto-renewal': '取消自动续订',
        'A unique subscription gives guaranteed discounts not only on your ticket, but also on tickets for your loved ones': NaN,
        'The subscriber can use the privileges both separately and together with his fellow travelers, whilefellow travelers - only together with the subscriber (personal data of passengers are indicated whensubscribing and are not subject to change).': NaN,
        'Read more': '详情',
        'Get a subscription': '收到订阅',
        'Your data': '您的信息',
        firstName: '名字',
        lastName: '姓',
        birthDate: '出生日期',
        latinFirstName: '名字（拉丁的）',
        latinLastName: '姓（拉丁的）',
        Required: '必填',
        'Enter date in dd.mm.yyyy format': '请选择日期    按照 日.月.年.  格式的',
        'For worlds flights': '国际航班的',
        'Add new Travel companion': '添加新的同路人',
        'Add Member': '添加{{成员}}',
        Cancel: '取消',
        'Select from Travel companions': '请选择同路人其中',
        'Add new': '添加新的',
        'Please, select at least one member': '请您选择最好是一名旅伴',
        'Choose your plan now': '选择费率',
        'You get more cost-efficient flights + greater comfort': '您将得到优惠的服务和更大的舒适度',
        'Select your plan now': '请选择自己的费率',
        Popular: '流行的',
        year: '年',
        Select: '选择',
        'Still have questions?': '您还有问题吗？'
    },
    SubsidySuggestPopup: {
        'Subsidy fares': '补贴费率',
        'For special categories of passengers on the flight, you can choose special subsidy fares': NaN,
        'A special fare is available when all passengers in an order are eligible for subsidy benefits.': '只有所有乘客都有资格领取补贴时，才可以下订单。',
        'Apply subsidy': '利用补贴',
        'Continue without subsidy': '按正常价格购买',
        'Choose passengers': '请选择类别'
    },
    Timer: {
        'Didn\'t get the code?': '没收到代码？',
        'Resend it': '重新发送',
        'Code sent': '代码已发送',
        'You can get new code in': '您可以过...时间再次请求代码',
        'Sending message': '正在发送代码'
    },
    Title: {
        companyName: 'Websky',
        'Find tickets': '搜索机票',
        'Checkout order': '办理订单手续',
        Payment: '订单付款',
        Account: '个人账户'
    },
    Toolbar: {
        Total: '应付',
        Continue: '继续',
        Back: '往后',
        'Oops...': '哎呀',
        'An unexpected error has occurred during the creating order process. Please try again later or contact a customer support service.': NaN,
        'OK, thank you': '明白，谢谢',
        'miles will be awared': '该航班赚...英里',
        'miles will be charged': '英里要注销',
        'This flight will be partially paid by miles': '此航班的部分费用将用英里支付。',
        'Fly with us and get miles': '搭乘我们的航班并获取英里'
    },
    TransferInfo: {
        layover: '在...换乘',
        'Change terminal': '更换航站楼',
        Terminal: '航站楼',
        Change: '更换'
    },
    translation: {
        d: '日',
        h: '小时',
        m: '米',
        s: '秒',
        kg: '公斤',
        'It looks like something went wrong': '好像出了什么问题',
        'Please reload the page or start a new search': '请刷新页面或开始新的搜索。',
        'Reload the page': '刷新页面',
        Refresh: '更新',
        'We couldn\'t find any flights for given dates': '我们没有找到所选日期的航班',
        'Unfortunately, there are no available flights for given dates or all tickets have been already sold out. Please select another date or modify your search parameters.': NaN,
        'Please select another date or modify your search. If you wish to purchase a ticket for this particular date, please contact customer support.': NaN,
        'It looks like search results are obsolete': '这些搜索结果似乎已过时。',
        'Please start a new search using the form above': '请使用上面的搜索表单开始新的搜索',
        'The specified cost is for all passengers and includes taxes and fees': '该价格适用于所有乘客，包括税费',
        'Local time': '当地时间',
        'Go to main page': '去主页面'
    },
    Traveller: {
        firstName: '名字',
        lastName: '姓',
        middleName: '父名',
        birthDate: '出生日期',
        email: '电子邮箱地址',
        phone: '电话号码',
        nationality: '国籍',
        Add: '添加',
        Required: '必填',
        'Please enter a valid email': '请输入正确的电子邮箱地址',
        'Please enter a valid phone': '请输入正确的电话号码',
        'Please enter a valid date': '请输入正确日期',
        'FLIGHT PREFERENCES': '偏重',
        Seats: '座位',
        Meal: '饮食',
        Save: '保存您的护照详细信息以加快购买机票流程',
        'Remove passengers data': '从列表中删除',
        Cancel: '取消',
        'Add companion': '添加同路人',
        'Add document': '添加文件',
        'Frequent Flyer': '添加会员卡',
        'Personal details': '个人帐户',
        'An error occurred while executing the request, please try again': '执行请求时出错，请重试',
        Contacts: '联系方式',
        'With the help of this data, we will be able to inform the fellow traveler about changes in booking, flight status, check-in time, etc. Optional data.': NaN,
        Documents: '文件',
        'Save the details of your internal or foreign passport, this will speed up the process of purchasing tickets.': '保存您的护照详细信息以加快购买机票流程'
    },
    VipServiceSelect: {
        'Different for other flight?': '为每个航班单独配置',
        Cancel: '取消',
        Confirm: '确定',
        Total: '一共',
        from: '从'
    },
    VisaForm: {
        applicableCountry: '接受国',
        birthPlace: '出生地',
        issueDate: '签证签发日期',
        issuePlace: '签证签发地点',
        number: '签证号',
        Required: '必填',
        'format error, only latin and digits': '只有拉丁字母和数字',
        'only latin and digits': '只有拉丁字母和数字',
        'Enter date in dd.mm.yyyy format': '请输入正确日期',
        'Incorrect date': '无效日期',
        'Latin characters only': '只有拉丁字母'
    },
    Voucher: {
        'E-voucher': '促销代码',
        Add: '添加',
        Done: '完毕',
        Details: '详情',
        'Enter code': '请输入促销代码'
    },
    Warnings: {
        Clarification: '请注意'
    }
};
