import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { getIsForeignCard } from '@websky/core/src/SearchForm/store/foreignCard/selectors';
import { setForeignCardAction } from '@websky/core/src/SearchForm/store/foreignCard/actions';
import { Switch, Tooltip } from '@websky/core';
import { initI18n } from '@websky/core/src/utils';
import { InfoDetails } from '@websky/core/src/Icons';
import { useGeolocationContext } from '../../Engine/Geolocation/Geolocation';
import css from './BottomRight.css';
initI18n('SearchForm');
const BottomRight = () => {
    const { t } = useTranslation('SearchForm');
    const dispatch = useDispatch();
    const isForeignCard = useSelector(getIsForeignCard);
    const { countryCode, loading } = useGeolocationContext();
    React.useEffect(() => {
        if (typeof countryCode === 'string') {
            dispatch(setForeignCardAction(countryCode === 'KZ'));
        }
    }, [countryCode]);
    const onToggleForeignCard = React.useCallback(() => {
        dispatch(setForeignCardAction(!isForeignCard));
    }, [dispatch, isForeignCard]);
    return (React.createElement(Switch, { activeClassName: css.active, labelClassName: css.label, mainClassName: cn(css.wrapper, 'resident'), label: React.createElement(React.Fragment, null,
            React.createElement(Tooltip, { tooltipClassName: css.tooltip, titleClassName: css.tooltip__title, title: React.createElement("span", { className: css.tooltip__titleText, dangerouslySetInnerHTML: { __html: t('ResidentInfo') } }), enterTouchDelay: 0 }, InfoDetails),
            t('Resident')), switchPosition: 'right', isActive: isForeignCard, isDisabled: loading, onClick: onToggleForeignCard }));
};
export default BottomRight;
