import cn from 'classnames';
import Segments from './components/SearchForm/Segments/Segments.css';
import Segment from './components/SearchForm/Segment/Segment.css';
import Value from './components/SearchForm/Value/Value.css';
import Datepicker from './components/SearchForm/Datepicker/Datepicker.css';
import Controls from './components/SearchForm/Controls/Controls.css';
import Passengers from './components/SearchForm/Passengers/Passengers.css';
import StartSearch from './components/SearchForm/StartSearch/StartSearch.css';
import Bottom from './components/SearchForm/Bottom/Bottom.css';
import SearchFormDatepicker from './components/SearchFormDatepicker/SearchFormDatepicker.css';
import PriceGraph from './components/PriceGraph/PriceGraph.css';
import Currency from './components/SearchForm/Currency/Currency.css';
import SearchForm from './components/SearchForm/SearchFormMain.css';
import MultiCity from './components/SearchForm/MultiCity/MultiCity.css';
const theme = {
    SearchForm: {
        SearchForm: {
            searchForm: SearchForm.container,
            row: SearchForm.row
        },
        Segments: {
            segments: Segments.segments
        },
        Segment: {
            cell: Segment.cell,
            cell_location: Segment.cell_location,
            cell_location_arrival: Segment.cell_location_arrival,
            cell_date: cn(Segment.cell_date, SearchForm.cell_date),
            alternative: Segment.alternative
        },
        Value: {
            wrapper: Value.wrapper,
            value: Value.value,
            code: Value.code,
            wrapper_alternative: Value.wrapper_alternative
        },
        Datepicker: {
            dates: Datepicker.dates,
            dates_focused: cn(Datepicker.dates_focused, Segment.dates_focused, SearchForm.dates_focused),
            dates__to: Datepicker.dates__to,
            dates__back: Datepicker.dates__back,
            placeholder: Datepicker.placeholder,
            label: Datepicker.label,
            label_hidden: Datepicker.label_hidden,
            dow: Datepicker.dow,
            alternative: Datepicker.alternative
        },
        Controls: {
            controls: Controls.controls
        },
        Passengers: {
            passengers: Passengers.passengers,
            selector: Passengers.selector,
            selector_focused: Passengers.selector_focused,
            title: Passengers.title,
            alternative: Passengers.alternative
        },
        StartSearch: {
            startSearch: cn(StartSearch.startSearch, SearchForm.searchButton)
        },
        Bottom: {
            bottom: Bottom.bottom,
            controls: Bottom.controls
        },
        MultiCity: {
            caption: MultiCity.caption
        },
        Currency: {
            selector: Currency.selector,
            selector_disabled: Currency.selector_disabled,
            currency: cn(Currency.currency, SearchForm.currency),
            item: Currency.item,
            arrowIcon: Currency.arrowIcon,
            dropdown: Currency.dropdown
        }
    },
    SearchFormDatepicker: {
        SearchFormDatepickerStyles: {
            popup: SearchFormDatepicker.popup,
            datepicker__disclaimer: SearchFormDatepicker.datepicker__disclaimer,
            footer__buttons: SearchFormDatepicker.footer__buttons
        }
    },
    PriceGraph: {
        PriceGraphStyles: {
            container: PriceGraph.dv__priceGraphContainer,
            calendar: PriceGraph.dv__priceGraphCalendar
        }
    }
};
export default theme;
