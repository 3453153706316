import { convertICULocale, getLocale } from '@websky/core/src/utils';
import i18next from 'i18next';
import { CardType } from '@websky/core/src/PromoLoader/types';
import { Locale } from '@websky/core/src/types';
import { Currency, CurrencySymbols } from '@websky/core/src/enums';
import { OauthServiceType } from '@websky/graphql';
import { zh } from './locales/zh';
let currentLocale = getLocale();
const isProd = process.env.NODE_ENV === 'production';
currentLocale = convertICULocale(currentLocale);
const uncorvertedCurrentLocale = getLocale();
export const companyInfo = {
    facebook: 'https://www.facebook.com/scat.airlines',
    instagram: 'https://www.instagram.com/scat.airlines/',
    vk: 'https://vk.com/scat.airlines',
    baggage: `https://www.scat.kz/${uncorvertedCurrentLocale}/luggage-and-hand-luggage/`,
    meal: null,
    seat: `https://www.scat.kz/${uncorvertedCurrentLocale}/seats/`,
    legal: `https://www.scat.kz/${uncorvertedCurrentLocale}/air-transportation-rules/`,
    privacyPolicy: `https://www.scat.kz/${uncorvertedCurrentLocale}/privacy-policy/`,
    exareRulesURL: `https://www.scat.kz/${uncorvertedCurrentLocale}/exchange/`,
    exarePrivacyPolicyURL: `https://www.scat.kz/${uncorvertedCurrentLocale}/privacy-policy/`,
    passengerConductRules: `https://www.scat.kz/${uncorvertedCurrentLocale}/rules-on-board/`,
    insurance: null,
    loyaltyProgramRegistrationUrl: ``,
    loyaltyProgramName: '',
    iataCode: 'DV',
    loyalty: {
        links: [
            {
                title: 'Status',
                url: ``
            },
            {
                title: 'Earn miles',
                url: ``
            },
            {
                title: 'Use miles',
                url: ``
            },
            {
                title: 'FAQ',
                url: ``
            }
        ]
    },
    multiInsurance: true,
    specialDocumentForInsurance: true,
    subsidyFaresConditionURL: ''
};
export const i18n = {
    ru: {
        Cart: {
            'The service is awaiting confirmation. Do not close or refresh the window.': 'Услуга ожидает подтверждения.'
        },
        FareGroup: {
            Recommended: 'Хит'
        },
        Title: {
            companyName: 'SCAT'
        },
        Passenger: {
            loyaltyNumber: 'Номер карты Nordwind Club'
        },
        Passengers: {
            '2-11 years': '2-12 лет',
            '12+ years': '15+ лет',
            'Children from two to eleven years old': 'Внутренние рейсы: от 2 до 14 лет',
            'Adults over twelve years old': 'Внутренние рейсы: от 15 лет'
        },
        Loyalty: {
            Status: 'Статусы',
            'Earn miles': 'Копить',
            'Use miles': 'Тратить',
            'Log in to LightJet Club': 'Вход в личный кабинет Nordwind Club'
        },
        ScatHeader: {
            Account: 'Кабинет'
        },
        Checkout: {
            'I accept the': 'Я ознакомился (-лась) и соглашаюсь с',
            'terms of agreement': 'общими правилами перевозок',
            'processing of my personal data': 'правилами поведения пассажиров',
            'fare rules': 'правилами тарифа'
        },
        FlightPrice: {
            Business: 'Субсидированный'
        },
        DesktopSummaryFlight: {
            'Baggage info': 'Выбранный тариф'
        },
        Exchange: {
            'Please select another date or modify your search. If you wish to purchase a ticket for this particular date, please contact customer support.': 'Пожалуйста, выберите другую дату или измените свой поиск. если вы хотите приобрести билет на эту конкретную дату, свяжитесь со службой поддержки клиентов по электронному адресу ',
            'Create request': 'Перейти к обмену',
            'Delete request': 'Новый запрос',
            'Your phone': 'Ваш телефон',
            'Exchange penalty': 'Дополнительные сборы за обмен',
            Total: 'Итого за обмен'
        },
        Refund: {
            Refund_CreateTicket: 'Перейти к возврату',
            'Successful refund. Confirmation and detalization have been sent to your e-mail': 'Заявка создана. Ответ будет отправлен на Ваш e-mail.',
            'The money will be returned to the card used to pay for the ticket within 15 days': 'Деньги поступят на карту, с которой были оплачены билеты, в течение 10 рабочих банковских дней.'
        },
        SeatMap: {
            'Pay attention!': 'Уважаемые пассажиры,',
            'The airline reserves the right to replace the aircraft.': '',
            'In the event of an aircraft replacement, passengers who have paid for the “seat selection” service will be provided with seats on the plane that are similar in price and comfort, while maintaining the following parameters': 'В целях обеспечения безопасности полета авиакомпания оставляет за собой право при необходимости пересадить следующую категорию пассажиров с аварийного места',
            '- Cost of services;': '- пассажиры младше 18 лет;',
            '- Location of the place relative to the window / aisle;': '- пассажиры с детьми до 2 лет;',
            '- Joint seating, if one was originally selected;': '- беременные женщины;',
            '- Placement in the front row of the cabin, if one was originally selected.': '- пассажиры с багажом в салоне (например, с музыкальным инструментом);\n- пассажиров с ограниченными возможностями.'
        },
        RetrieveBooking: {
            'Booking ID': 'Код бронирования',
            'Enter latin symbols': 'Только латинские буквы',
            'Enter your book code or ticket number': 'Введите код брони',
            'Enter 6 latin symbols': 'Введите 6 латинских букв'
        },
        SearchFormDatepicker: {
            'Approximate prices for 1 person for 1 direction': 'Ориентировочные цены на 1 человека в 1 сторону указаны только на текущий месяц, для проверки стоимости на последующие месяца просим осуществить поиск.'
        },
        SearchForm: {
            'Passengers count': 'Кол-во пассажиров',
            Outbound: 'Отправление',
            Date_from: 'возвращение',
            Resident: 'Резидент',
            ResidentInfo: '<span>Валюты EUR, USD, RUB</span> доступны для иностранных граждан'
        },
        FlightStatus: {
            'Flight Status': 'Карта полетов',
            'The latest data update was performed': 'Было выполнено последнее обновление данных',
            'City / Airport / Flight number': 'Город / Аэропорт / Номер рейса'
        }
    },
    en: {
        Cart: {
            'The service is awaiting confirmation. Do not close or refresh the window.': 'The service is awaiting confirmation.'
        },
        Title: {
            companyName: 'SCAT'
        },
        Passenger: {
            loyaltyNumber: 'Nordwind Club membership card'
        },
        Loyalty: {
            'Log in to LightJet Club': 'Log in to Nordwind Club'
        },
        ScatHeader: {
            Account: 'Account'
        },
        FareGroup: {
            Recommended: 'Popular'
        },
        Checkout: {
            'I accept the': 'I am informed and I accept',
            'terms of agreement': 'conditions of carriage',
            'processing of my personal data': 'rules for the conduct of passengers',
            'fare rules': 'fare rules'
        },
        Passengers: {
            '2-11 years': '2-12 years',
            '12+ years': '15+ years',
            'Children from two to eleven years old': 'Domestic: 2 to 14 yrs',
            'Adults over twelve years old': 'Domestic: 15+ yrs'
        },
        FlightPrice: {
            Business: 'Subsidized'
        },
        Refund: {
            'Successful refund. Confirmation and detalization have been sent to your e-mail': 'The request has been created. Response to the letter will sent to your e-mail.',
            'The money will be returned to the card used to pay for the ticket within 15 days': 'The money will be returned to the card used to pay for the ticket within 10 days'
        },
        SeatMap: {
            'Pay attention!': 'Dear passengers!',
            'The airline reserves the right to replace the aircraft.': '',
            'In the event of an aircraft replacement, passengers who have paid for the “seat selection” service will be provided with seats on the plane that are similar in price and comfort, while maintaining the following parameters': 'To ensure flight safety, the airline may, if required, relocate the following category of passengers from the emergency exit seat',
            '- Cost of services;': '- passengers under the age of 18;',
            '- Location of the place relative to the window / aisle;': '- passengers with children under the age of 2;',
            '- Joint seating, if one was originally selected;': '- pregnant women;',
            '- Placement in the front row of the cabin, if one was originally selected.': '- passengers carrying luggage in cabin (for example, carrying a musical instrument);\n- passengers with disabilities.'
        },
        RetrieveBooking: {
            'Booking ID': 'Booking number',
            'Enter latin symbols': 'Enter latin symbols',
            'Enter your book code or ticket number': 'Enter your book code',
            'Enter 6 latin symbols': 'Enter 6 latin symbols'
        },
        SearchFormDatepicker: {
            'Approximate prices for 1 person for 1 direction': 'Approximate prices for 1 person in 1 way are indicated only for the current month, to check the cost for subsequent months, please start search.'
        },
        SearchForm: {
            'Passengers count': 'Passengers count',
            Outbound: 'Outbound',
            Date_from: 'Return',
            Resident: 'Resident',
            ResidentInfo: '<span>EUR, USD, RUB currencies</span> are available for foreign citizens'
        },
        FlightStatus: {
            'Flight Status': 'Flight map',
            'The latest data update was performed': 'The latest data update was performed',
            'City / Airport / Flight number': 'City / Airport / Flight number'
        }
    },
    kk: {
        Cart: {
            'The service is awaiting confirmation. Do not close or refresh the window.': 'The service is awaiting confirmation.'
        },
        Title: {
            'Find tickets': 'Билетті іздеу',
            companyName: 'SCAT'
        },
        Passengers: {
            'Latin symbols and digits': 'латын таңбалары мен белгілері',
            'Infants up to two years old, without seat': ' Орны жоқ екі жасқа дейінгі сәбилер ',
            'Infants up to two years old, with seat': ' Орны бар екі жасқа дейінгі балалар ',
            '2-11 years': '2-12 жас',
            '12+ years': '15+ жас',
            'Children from two to eleven years old': 'Ішкі рейстер: 2-14 жас',
            'Adults over twelve years old': 'Ішкі рейстер: 15+ жас'
        },
        Checkout: {
            'I accept the': 'Мен таныстым және қабылдаймын',
            'processing of my personal data': 'жолаушылардың мінез-құлық ережелері',
            'Sign up for NORDWIND newsletter and enjoy invites to major sales and exclusive offers and always be in the know.': 'SCAT әуекомпаниясының жаңалықтарына жазылыңыз және барлық сатылымдарға, керемет ұсыныстарға шақыру алыңыз және әрқашан біздің жоспарларымыздан хабардар болыңыз.',
            'terms of agreement': ' келісім шарттары',
            'Wow, the price has changed to': 'Ой, баға дейін өзгерді',
            'Please, read the rules of service and accept it': 'Жалғастыру үшін, қызмет көрсету ережелерімен танысып, Растаңыз',
            Status: ' Тапсырыс статусы ',
            'More time to get comfortable on board.': 'Басқа жолаушыларды отырғызуға дейін.',
            'Coverage for medical expenses, also suitable for visa. Business lounge in case of delay. Get 30 miles': ' Медициналық жағдайларды және визалық жағдайларды қамтиды. Қосымша 30 милли жүріңіз ',
            'No worries about traffic jams, arrival at the airport strictly on schedule.': 'Кептелістер туралы алаңдамаңыз. Қонғаннан кейін бірден қала орталығына дейін трансфер қамтылған',
            'Enjoy the extended range of dishes and top-notch services on board, earn extra miles': 'Ұшақтарда үлкен ассортименттегі тағамдар мен жоғары деңгейлі қызметтерді пайдаланып, қосымша миль табыңыз',
            'Oops...': 'Ой...',
            Email: 'Электронды пошта',
            'Please enter a valid email': 'Электронды поштаңызды тексеріңіз',
            'Now you will be redirected to the previous version of the site. There you can choose ancillary services.': 'Енді біз сізді сайттың алдыңғы нұсқасына бағыттаймыз. Уақытша сіз өзіңізге қажетті қосымша қызметтерді таңдап, рейсті ұмытылмастай ете аласыз.',
            'Improve your flight right now by adding the pleasant and necessary comfort with special food, extra baggage and the best places with amazing views.': 'Ұшуыңызды ерекше етіп жасаңыз. Тапсырысыңызға керемет тағамдарды, қосымша жүкті алып, илюминатордан әсем көріністі тамашалаңыз.',
            'Feel the taste in flight!': ' Ұшудан ләззат алыңыз!',
            'Transportation of impressions no limitations': ' Шексіз тасымалдау ',
            'Fly in comfort': ' Жайлы ұшыңыз ',
            'general transportation rules': 'Тасымалдаудың жалпы ережелері',
            'and I accept it. I am aware that the fee for unused additional services is not refundable.': 'және оларды қабылдаймын. Мен қолданылмаған қосымша қызметтер үшін төлем қайтарылмайтыны жайында ақпаратпен таныспын. Қажет жағдайда техникалық қызмет көмегіне жүгінемін',
            'You can select seats using the online registration service after issuing tickets': 'Сіз билетті тіркегеннен кейін,онлайн тіркеу сервисін қолдана отырып орын таңдай аласыз.',
            'Seat selection is available in the online registration service': 'Онлайн тіркеу сервисінде орын таңдау қол жетімді',
            Refund: 'Кайтару',
            Exchange: 'Айырбастау',
            'Capsule hotel': 'Капсулды қонақ жайы',
            'terms of tariff': 'тарифтік шарттармен',
            'fare rules': 'тариф ережелерімен'
        },
        DesktopFlightInfo: {
            'Arrival the next day': 'Келесі күні ұшып келу ',
            'Service is unavailable on this flight': ' Бұл рейсте қызмет көрсетілмейді. ',
            'Select from': 'Бастап таңдау',
            'Total duration': 'Ұшу уақыты',
            'Stop in': 'Ауысып отыру келесі рейске',
            from: 'Бастап',
            Select: 'Таңдау'
        },
        FlightPrice: {
            Business: 'Субсидированный',
            'Select from': 'Бастап таңдаңыз',
            from: 'Бастап'
        },
        DesktopSummaryFlight: {
            'Baggage info': 'Таңдалған тариф',
            'Fare rules': 'Тарифтерді қолдану ережелері',
            Details: ' Егжейлер ',
            Charter: 'Чартерлік'
        },
        Account: {
            'Are you sure you want to delete your account?': 'Аккаунты шынымен жойғыңыз келе ме?',
            'We sent verification code to new email': ' Біз растау кодын жаңа электронды мекен жайына жібердік',
            'Please confirm your email': ' Электрондық пошта мекен жайын растаңыз',
            'Email is already in use by another account': ' Бұл электрон поштасы басқа аккаунта қолданылуда ',
            'Failed to fetch': ' Сұранысты орындау кезінде қателік туындады ',
            'Oops...': 'Ой...',
            'An error occurred while executing the request. Please try again later or contact a customer support service.': ' Сұранысты орындау кезінде қателік туындады. Әрекетті қайталаңыз немесе тұтынушыларға қызмет көрсету орталығына хабарласыңыз',
            'Orders that were booked on a previous version of the site are available': ' Сайттың алдыңғы нұсқасында орналастырылған тапсырыстар , қол жетімді ',
            'this link': 'Сілтеме бойынша'
        },
        AdditionalServiceCard: {
            'Only digits': '12 санды енгізіңіз'
        },
        BaggagePage: {
            'Transportation of impressions no limitations': 'Шексіз тасымалдау'
        },
        Checkin: {
            'Oops, something went wrong': 'Ой,бірдеңе дұрыс болмады',
            'But don\'t worry, all of our systems have been duplicated several times. You can register on the backup version of the site.': 'Уайымдамаңыз,біздің барлық системамыз қайталанған. Сіз резервтегі сайтта қайта тіркеле аласыз.',
            Go: 'Резервтегі сайтқа ауысу.',
            'Try again': ' Қайтадан байқап көріңіз',
            From: 'из',
            'Please, enter your contacts': ' Өтініш,өзіңіздің байланыс мәліметтеріңізді енгізіңіз.',
            'Inflight insurance or baggage insurance is your travel confidence.': 'Ұшақтарды сақтандыру немесе жүкті сақтандыру - бұл сіздің саяхатыңызға сенімділік.',
            'Oops...': 'Ой',
            'An unexpected error has occurred during the start of the check-in process. Please try again later or contact a customer support service.': 'Онлайн тіркелу кезінде ақау туындады. Әрекетті қайталаңыз немесе тұтынушыларға қызмет көрсету орталығына хабарласыңыз.',
            'Check-in Successful': 'Тіркеу аяқталды ',
            'time to complete online check-in has expired': ' онлайн тіркелуге берілген уақыты аяқталды ',
            'Anything else for your trip?': 'Тағы да бірдеңе қажет па?',
            Download: 'Жүктеу'
        },
        CheckinRules: {
            'Terms & Conditions': 'Онлайн тіркелу шарттары мен ережелері ',
            'Please read and accept the online check-in terms and conditions': 'Онлайн тіркелу шарттарымен және ережелерімен танысып,растаңыз'
        },
        CheckoutOrderSummary: {
            'today before': ' соңғы толем уақыты ',
            'Order number': 'Тапсырыс нөмірі'
        },
        CompareFares: {
            'Internal error': 'Жүйелік қате',
            'Compare fare families': 'Тариф топтамаларын салыстыру',
            'Comfort kit': 'Жайлылық жинағы',
            'Airport sevices': 'Әуежай қызметтері',
            'Priority boarding': ' Кезектен тыс отырғызу ',
            'Priority baggage delivery': ' Кезектен тыс жүкті жеткізу ',
            Note: ' Ескерту '
        },
        Contacts: {
            'Contact details are necessary for us to be able to pass on the latest flight information.': ' Байланыс контакттары брондау туралы ақпарат алу үшін қажет.',
            Email: 'Электрондық пошта',
            'Want to speed up passenger data entry? Sign in and continue.': ' Брондауды тездеткіңіз келе ме? Жүйеге кіріңіз.',
            'Min 10 digits': 'Минимум 10 сан'
        },
        CustomerContacts: {
            'Please, enter your contacts': 'Байланыс контакттарыңызды еңгізуін сұраймыз'
        },
        CustomerContactsForm: {
            'Please, enter your contacts': 'Байланыс контакттарыңызды еңгізуіңізді сураймыз',
            Done: 'Дайын',
            Email: 'Электрондық пошта',
            Phone: 'Телефон',
            Required: 'Міндетті өріс',
            'Please enter a valid email': 'Электрондық поштаның мекен жайын тексеріңіз'
        },
        Datepicker: {
            week_short_2: 'Ср'
        },
        DesktopFlight: {
            Arrival: 'Ұшып келу',
            stop: ' Ауысып отыру',
            Nonstop: 'Тікелей',
            Details: 'Егжейлер',
            'Need ': 'Қажет ',
            'the ability to refund ticket': 'Билет қайтару мүмкіндігі',
            and: 'және',
            'the ability to exchange ticket': 'Билет айырбастау мүмкіндігі'
        },
        Exchange: {
            'Change ticket': 'Билетті айырбастау',
            'Add new request': 'Жаңа тапсырыс',
            'Select passengers': 'Жолаушыларды таңдаңыз',
            Back: 'Кайту',
            Next: 'Келесі',
            'Select flights': 'Рейсті таңдаңыз',
            'Successful payment': 'Сәтті төлем',
            'Failed payment': 'Сәтсіз төлем',
            'Select new flight': 'Жаңа рейсті таңдаңыз',
            'Open calendar': 'Күнін таңдау',
            'You’ve chosen': 'Сіздің таңдауыңыз',
            Clear: 'Ошіру',
            Search: 'Іздеу',
            'Change flight': 'Басқа рейсті таңдау',
            Note: 'Ескерту',
            'Exchange restrictions': 'Айырбастау шектеулері',
            'The possibility and cost of changes will be calculated by the operator after sending the application. The answer will be sent to your E-mail and is also available on the order page.': ' Айырбастау мүмкіндігі мен толем ақысы тапсырыс жіберілген соң оператормен есептеледі. Жауап сіздің электрондық поштаңызға жіберіледі және осы парақшада көрсетіледі.',
            'After submitting the request, the seats you have selected will be canceled.': 'Тапсырыс жіберілген соң алдында таңдаған орындар жоққа шығарылады.',
            'Create request': 'Тапсырыс құру',
            'Your name': 'Сіздің атыңыз',
            Email: 'Email',
            'I agree with the': 'Мен келісемін',
            'Terms of Service': 'Қызмет көрсету ережелерімен',
            And: 'және',
            'Privacy Policy': 'құпиялық саясатпен',
            'Your phone': 'сіздің телефон нөміріңіз',
            'Your comment': 'Сіздің пікіріңіз',
            'I agree to the Terms of Service and Privacy Policy': 'Мен ережелермен келісемін',
            'Request #': 'Тапсырыс №',
            Create: 'Құрылған уақыты',
            'Your comments': ' Сіздің пікіріңіз ',
            'The cost of changes will be calculated by the operator after choosing the option and sending the request to the operator.': ' Айырбастау толем ақысы тапсырыс жіберілген соң оператормен есептеледі ',
            'Local time indicated': 'Жергілікті уақыт',
            'Order must be paid by {{time}} on {{date}}': ' Тапсырыс төлену уақыт шектеуі {{date}} {{time}}',
            InProcess: 'Қарастырулыда',
            NeedConfirm: 'Растау күтілуде',
            AwaitingPayment: 'Төлем күтілуде ',
            Finished: 'Аяқталды',
            Rejected: 'Қабылданбады',
            Refused: 'Жойылды',
            TimelimitExpired: 'Төлену уақыты шектен шықты',
            'If you change your mind, or want to create a new request with different parameters, delete this request': ' Егер ойыңыз өзгерген жағдайда немесе жаңа тапсырыс жасағыңыз келсе ағымдағы тапсырысты жою қажет ',
            'Delete request': 'Тапсырысты жою',
            'The change request was deleted. Want to create a new request?': ' Ағымдағы тапсырыс жойылады. Жаңа тапсырыс жасауға өту қажет пе?',
            Yes: 'Ия',
            No: 'Жоқ',
            'Limited exchange time': 'Уақыт шектеуі',
            'Offer valid until {{date}}': 'Ұсыныс жарамды уақыты {{date}}',
            'Exchange penalty': 'Айырбастау айыппұлы',
            Total: 'Барлығы',
            Required: 'Міндетті өріс',
            'Please, read the rules of service and accept it': 'Ережелермен танысуыңызды жане келісуіңізді сураймыз',
            'Please enter a valid email': 'Дұрыс email еңгізіңіз',
            'Please enter a valid phone': 'Дұрыс телефон нөмірін еңгізіңіз ',
            'Whole flight': 'Барлық бағыттар',
            'We couldn\'t find any flights for given dates': 'Таңдаған күнге рейстер табылмады',
            'Please select another date or modify your search. If you wish to purchase a ticket for this particular date, please contact customer support.': 'Басқа күнді таңдауыңыз немесе іздеу егжейлерін қайта еңгізіңіз. Егер сіз дәл осы күнге билет сатып алғыңыз келсе, тұтынушыларға қызмет көрсету орталығына хабарласыңыз.',
            for: 'для',
            'Successfully create new booking': ' Жаңа тапсырыс сәтті құрылды ',
            'View new booking': ' Жаңа тапсырысты ашыңыз ',
            'Request for changes': 'Айырбастауға тапсырыс',
            'Payment exchange': 'Төлеу',
            'Oops...': 'Ой...'
        },
        FareGroup: {
            Select: 'Таңдау',
            Choose: 'Таңдау ',
            Rules: 'Ережелер'
        },
        FareLock: {
            'Check your selection and pay fare lock': 'Таңдауыңызды тексеріп, төлемге көшіңіз',
            'No need to worry about the price increase or about the fact that all tickets will be sold out, now you can fix the ticket price for {{hours}} {{plural}}': 'Бағалардың өсуіне немесе барлық билеттердің сатылып кетуіне алаңдаудың қажеті жоқ, себебі сіз билет бағасын {{hours}} {{plural}} уақытына дейін ұстап тұра аласыз'
        },
        FlightCard: {
            Flight: 'Рейс',
            Duration: 'Ұшу уақыты'
        },
        FlightInfo: {
            Details: 'Ұшу егжейлері'
        },
        FlightInfoModalContent: {
            'You choose': 'Сіздің таңдауыңыз',
            'Select your bundle': 'Тарифті таңдаңыз',
            Economy: 'Эконом',
            'Flight info': 'Рейс ақпараты',
            'More details': 'Толығырақ ақпарат',
            'View full conditions': 'Тарифтердің толық салыстырымы',
            'comfortable salon': 'Жайлы салон',
            'access to wifi': 'Wifi жетімділігі',
            'hot and children\'s menu': 'Ыстық және балалар тағамы',
            'comfortable seating': 'Жайлы орын',
            'meals are included in the price': 'Тамақтану қосылған',
            'Compare fares': 'Тарифтерді салыстыру',
            'Fare rules': 'Тарифтерді қолдану ережелері'
        },
        FlightsListControls: {
            Filter: 'Іздеу',
            Nonstop: 'Тікелей рейс',
            'Flight Num': 'Рейс нөмірі',
            'Enter airline code or flight number': 'Әуе компанияның кодын немесе рейс нөмірін еңгізіңіз',
            Schedule: 'Кесте'
        },
        GuardedApolloProvider: {
            'Unfortunately, something went wrong. Please try again or contact with us': ' Өкінішке орай, бірнәрсе дұрыс емес. Кейінірек қайталап көріңіз '
        },
        IncludedOptions: {
            'Upgrade cabin baggage': 'Қолжүкті қосу',
            'Get {{upgradedCabinBaggage}} of cabin baggage onboard, instead of {{includedCabinBaggage}}': '{{upgradedCabinBaggage}} орнына қолжүк {{includedCabinBaggage}}'
        },
        InfoCard: {
            'Frequent Flyer programm': ' бонустық бағдарлама ',
            Edit: ' Өзгерту '
        },
        Location: {
            Terminal: 'Терминал',
            Details: 'Толық ақпарат'
        },
        LocationAutocomplete: {
            from: 'Қайдан',
            to: 'Қайда'
        },
        LoginPage: {
            'Email address': 'Электрондық пошта мекен-жайы',
            'Use email': ' Электрондық пошта арқылу кіру',
            'Please enter a valid email': 'Электрондық поштаңызды енгізіңіз',
            'use social networks': 'Әлеуметтік желілер арқылы кіру',
            'or another way to sign in': ' Басқа кіру жолдары ',
            'We sent verification code to the': ' Біз растау кодын жібердік ',
            'you have just logged in': ' Сіз жүйеге сәтті кірдіңіз ',
            'You are logged in for the first time, please read the terms and conditions and give consent': ' Сіз алғаш рет жүйеге кірдіңіз, шарттармен танысып, дербес деректерді қолдануға келісіміңізді растаңыз ',
            'I agree to the': ' Мен келісім беремін ',
            'terms of service': 'жеке мәліметтерімді қолдануға',
            'Please, read the rules of service and accept it': ' Жалғастыру үшін, сіздің жеке деректерді қолдануға келісіміңізді растаңыз '
        },
        MetasearchLoader: {
            'Please reload the page': ' Өтініш, парақшаны жаңартыңыз '
        },
        MobileAuthForm: {
            'Sign in with email': 'Электрондық пошта арқылу кіру'
        },
        MobileSegmentInfo: {
            Nonstop: 'Тікелей',
            stop: ' Ауысып отыру '
        },
        MobileStepbar: {
            Flights: 'Рейстер',
            Economy: 'Эконом класс',
            'Infant with seat': 'Жеке орны бар сәби',
            Details: 'Егжейлер',
            INS: ' Жеке орны бар сәби',
            Contact: 'Байланыс контакт'
        },
        MobileSummaryFlight: {
            Returning: ' Кайдан ',
            Departing: ' Кайда '
        },
        OrderCard: {
            'Booking ID': 'Брон нөмірі',
            'Security code': 'Қауіпсіздік коды',
            Passengers: 'Жолаушылар',
            Details: 'Толығырақ',
            Transactions: 'Операциялар',
            Download: 'Жүктеу',
            Confirmed: 'Расталды',
            'Online check-in started': 'Онлайн тіркеу басталды',
            'Review:Waiting for payment': 'Төлем күтілуде',
            'Review:Expires at': 'Дейін жарамды',
            'Review:Expires today at': 'Қарастырылуда:Жарамды бүгінгі уақытқа дейін',
            'Account:Waiting for payment': ' Төлем күтілуде ',
            'Account:expires at': ' Дейін жарамды ',
            'Account:expires today at': ' Жарамды бүгінгі уақытқа дейін '
        },
        OrderChangeHistoryModal: {
            History: 'Тарих',
            'Loading...': 'Жуктелуде...',
            'History is empty': 'Тарихы жоқ',
            Time: 'Күн/Уақыт',
            Action: 'Әрекет'
        },
        OrderSummary: {
            from: 'Қайдан',
            to: 'Қайда',
            Manage: 'Басқару'
        },
        Page404: {
            'Take me home': 'Бас парақшаға оралу'
        },
        Passenger: {
            'Infant with seat': 'Орны бар сәби',
            email: 'Электрондық пошта',
            birthDate: 'Туылған күні',
            loyaltyName: 'бонустық бағдарлама картасы',
            loyaltyNumber: ' бонустық бағдарлама картасының нөмірі',
            ResidencePermit: 'Мекендеу қағазы ',
            'Min 10 digits': 'Минимум 10 сан',
            'Example +1234567890': 'Мысалы: +1234567890',
            'Incorrect email': 'Дұрыс электрондық пошта мекенжайын енгізіңіз ',
            loyalty: 'бонустық бағдарлама ',
            latinFirstName: 'Аты (латынша)',
            latinLastName: 'Тегі (латынша)',
            latinMiddleName: 'Әкесінің аты (латынша)',
            docIssued: 'Берілген күні',
            docIssuedBy: 'Берген мекеме',
            city: 'Қала',
            country: 'Ел',
            'Latin symbols and digits': ' латын таңбалары мен белгілері '
        },
        PaymentForm: {
            'Credit or debit card': 'Пластик картамен',
            'Pay by credit or debit card': ' Пластик картасымен төлеу ',
            'In the next step you will be taken to a Google Pay page.': ' Келесі қадамда сіз Google Pay төлем парқшасына өтесіз.',
            Airticket: 'Әуе билеті'
        },
        PaymentTimeLimit: {
            'time to complete booking has expired': 'Тапсырыс беру уақыты бітті',
            'Cancel order': ' Тапсырыстың күшін жою '
        },
        PriceGraph: {
            min: 'минимум',
            max: 'максимум',
            'Approximate prices for 1 person for 1 direction': ' 1 адамға 1 бағыт бойынша болжамалы бағалар көрсетілген ',
            'Price for 1 direction': 'Бір бағытқа төлем'
        },
        PriceLock: {
            'Lock this price! We will wait for you': ' Бағаны ұстап турыңыз! Біз  сізді күтеміз ',
            'Lock it for 72 hours from just': 'Бағаны 72 сағатқа ұстап тұру небәрі'
        },
        QuickSearchForm: {
            Adult_1: ' Ересек ',
            Adult_2: ' Ересек ',
            Child_2: ' Бала',
            Infant_1: 'Сәби',
            Infant_3: 'Сәби',
            ADT_1: 'ересек',
            ADT_2: 'ересек',
            passenger_1: 'жолаушы',
            passenger_2: 'жолаушы'
        },
        RecommendedBadge: {
            'Best choice': 'Керемет таңдау'
        },
        Refund: {
            'I agree with the': 'Мен',
            'Terms of Service': 'Қызмет көрсету ережелерімен',
            And: 'және',
            'Privacy Policy': 'құпиялылық саясатымен келісемін.',
            New: 'Жаңа тапсырыс',
            AllDataSaved: ' Деректер сақталды ',
            Adopted: ' Тапсырыс қабылданды ',
            Calculated: ' Қайтару сомасы есептелді ',
            ClaimReceived: ' Тапсырыс жіберілді',
            AwaitingPayment: 'Қайтару сомасы күтілуде',
            TimelimitExpired: ' Растау уақыты аяқталды ',
            Finished: 'Аяқталды',
            Cancelled: 'Жойылды',
            Rejected: ' Қабылданбады ',
            NeedConfirm: ' Растауды күтілуде ',
            InProcess: ' Процесс барысында ',
            'Add new request': 'Жаңа тапсырыс беру',
            Refused: ' Пайдаланушы қабылдамады ',
            FlightCancellationOrDelay: ' Билетте көрсетілген рейстің тоқтатылуы немесе кешігуі;',
            Rerouting: ' Бағыттың өзгеруі (қолайсыз ауа райы жағдайлары мен форс-мажор жағдайларын қоспағанда));',
            FlightOperationOutOfSchedule: 'Выполнение полетов вне графика;',
            SeatUnavailability: 'Отсутствие места для пассажира на рейс и дату, указанные в билете;',
            EnduringSecurityScreening: 'Если пассажир не может подняться на борт самолета из-за длительного досмотра / досмотра, при условии, что во время такого досмотра / досмотра не будет обнаружено никаких запрещенных веществ или предметов;',
            FailedEnsureConnectionOfFlights: 'Если перевозчик не обеспечил стыковку рейсов при условии, что стыковочные рейсы являются разовыми;',
            Illness: 'Болезнь пассажира члена его / ее семьи, путешествующего вместе с пассажиром, если такое заболевание подтверждено соответствующими медицинскими документами, или в случае смерти пассажира или члена его / ее семьи, если смерть подтверждена соответствующими медицинскими документы, при условии, что об этом сообщается до окончания времени регистрации, указанного в билете; Более подробная информация о требованиях к таким медицинским документам изложена здесь;',
            FailedToProvideServices: 'Если перевозчик не предоставил пассажиру услуги в соответствии с классом, указанным в билете;',
            FailedToIssueTicketAppropriately: 'Если перевозчик или его уполномоченный агент не выписали билет надлежащим образом;',
            OtherCases: 'В иных случаях, когда пассажир не обеспечен перевозкой по вине перевозчика;',
            MedicalCertificate: ' Медициналық анықтама ',
            Request: 'Тапсырыс',
            Create: 'Құрылды',
            'Your comments': 'Сіздің пікіріңіз',
            'Refund amount': 'Қайтарылуға',
            'Ticket price': 'Тапсырыс төлем ақысы',
            'FareExchange penalty': 'Айырбастау айыппұлы',
            Continue: 'Жалғастыру',
            Back: 'Артқа',
            'Select passengers': 'Жолаушыларды таңдаңыз',
            'Choose the reason for refund': 'Қайтару себебін таңдаңыз',
            'Unvoluntary refund': 'Мәжбүрлі қайтарылым',
            'Voluntary refund': 'Еркін қайтарылым',
            'my plans have changed': 'Менің жоспарларым өзгерді',
            'Attach the required files': 'Қажетті файлдарды жуктеңіз',
            'File format: jpg, pdf. File size: no more than 5MB': 'Файл Форматы: jpg, png, pdf, doc, docx. Файл мөлшері: максимум 5MB',
            'Your name': 'Сіздің атыңыз',
            'Your email': ' Сіздің Электрондық поштаңыз',
            'Your phone': ' Сіздің телефоныңыз',
            'I agree to the': 'Мен келісемін',
            'Terms of Service and Privacy Policy': ' Қызмет көрсету ережелерімен және құпиялылық саясатпен ',
            'Create request': 'Тапсырыс жасау',
            'Only letters': 'Тек әріптер',
            Required: 'Міндетті өріс',
            'Wrong email': 'Қате электрондық пошта',
            'Wrong phone': ' Қате телефон нөмірі ',
            For: 'үшін',
            Files: 'Файлдар',
            'Refund ticket': 'Билетті қайтару',
            'Refund restrictions': 'Қайтарылудағы шектеулер',
            Note: 'Ескеріңіз',
            'The possibility and amount of refund will be calculated by the operator after sending the application. The answer will be sent to your E-mail and is also available on the order page.': ' Қайтарылу мүмкіндігі мен толем ақысы тапсырыс жіберілген соң оператормен есептеледі. Жауап сіздің электрондық поштаңызға жіберіледі және осы парақшада көрсетіледі.',
            'After submitting the request, the seats you have selected will be canceled.': ' Тапсырыс жіберілген соң алдын таңдаған орындар жоққа шыгарылады.',
            'The money will be returned to the card used to pay for the ticket within 10 days': ' Ақша билетке төленген картаға 10 күн ішінде қайтарылады.',
            Reject: ' Бас тарту ',
            Confirm: 'Растау',
            'The request is accepted for processing. The answer will be sent to your email and also available on the order page.': ' Тапсырыс қабылданды әрі оңделуде, жауап сіздің электрондық поштаңызға жіберіледі және осы парақшада көрсетіледі.',
            'The request was deleted. Want to create a new request?': 'Тапсырыс жойылады. Жаңа тапсырыс жасағыңыз келе ме?',
            'Successful refund. Confirmation and detalization have been sent to your e-mail': 'Тапсырыс құрылды. Жауап сіздің электрондық поштаңызға жіберіледі.',
            Ok: 'Oк',
            Yes: 'Ия',
            No: 'Жоқ',
            'Request for refund': 'бас тарту тапсырысы',
            'Successfully refund': 'Сәтті қайтарылым ',
            'Delete request': 'Тапсырысты жою',
            FlightNumberChange: 'Рейс нөмірін өзгерту',
            FlightRetimed: 'Рейс қалпына келтірілді',
            FlightCancelled: 'Рейс тоқтатылды',
            PermanentWithdrawalOfService: ' Қызметтің үнемі үзілуі ',
            LaborDispute: ' Еңбек дауы ',
            Bereavement: 'Қайтыс болу',
            UnableToObtainNecessaryDocumentation: ' Қажетті құжаттарды алу мүмкін емес ',
            Strike: ' Ереуіл ',
            Weather: 'Ауа райы',
            NaturalDisaster: ' Табиғи апат ',
            UnacceptableReaccommodation: ' орынсыз орналастыру ',
            Refund_Passengers: 'Жалғастыру',
            Refund_Reason: ' Жалғастыру ',
            Refund_CreateTicket: ' Жалғастыру ',
            'terms of tariff': 'тарифтік шарттармен',
            'The money will be returned to the card used to pay for the ticket within 15 days': '10 күннің ішінде билет құны төленген картасына қайтарылым сомасы қайтарылад'
        },
        RegisteredPassenger: {
            CarryOn: 'Қол жүк',
            CheckedBaggage: 'Тіркелген жүк',
            'You are going to cancel online check-in for passenger {{passenger}}. Are you sure about this?': ' Жолаушыға онлайн-тіркеу қызметінен бас тартуды қалайсыз ба? {{passenger}}?',
            'Send to email': 'Электрондық поштаға жіберу'
        },
        ResendTicketsForm: {
            Email: 'Электрондық пошта мекен жайы',
            'Resend booking': 'Билеттерді қайта жіберу',
            'Failed to send tickets to the specified mail, try again later': ' Билеттерді көрсетілген поштаға жіберу мүмкін емес, қайталап көріңіз ',
            'Please enter a valid email': 'Еңгізілген электрондық поштаны тексеріңіз'
        },
        Results: {
            Filters: 'Фильтрлар',
            Arrival: ' Үшып келу уақыты ',
            Departure: 'Ұшу кету',
            'Best flight': 'Үздік рейс',
            Returning: ' Кайдан ',
            Departing: ' Қайда ',
            'Selected flights': 'Таңдалған рейстер',
            'You\'ve chosen': 'Таңдалған рейстер',
            'Departure time': 'Ұшып кету уақыты',
            'We can offer you another flights for the same dates.': ' Біз сізге сол күнге басқа рейстер ұсына аламыз.',
            'Show price diagram': 'Баға графигі',
            'Select flight': 'Рейсті таңдаңыз',
            'Flight time': 'Ұшу уақыты'
        },
        RetrieveBooking: {
            'Security code': ' Кіруге рұқсат коды немесе жолаушының тегі ',
            'Enter passenger last name': 'Жолаушының тегін енгізіңіз ',
            'Enter your book code or ticket number': 'Брондау кодын енгізіңіз',
            'Please enter ticket number for passenger you would like to check-in': ' Тіркеуге қажет жолаушының билет нөмірін еңгізіңіз ',
            'You can find security code in your booking confirmation email': ' Броньды растайтын кіру кодын электрондық поштадан табуға болады ',
            'Booking ID': 'Брон нөмірін енгізіңіз',
            'Enter latin symbols': 'Тек латын әріптері',
            'Enter 6 latin symbols': '6 латын әріптерін енгізіңіз'
        },
        SearchForm: {
            'Please select different airports': ' Әртүрлі әуежайларды таңдаңыз ',
            Outbound: 'ұшу күні',
            Return: 'Қайдан',
            arrival: 'Келу',
            departure: 'Ұшу',
            passenger_plural: ' жолаушылар ',
            Passengers: ' жолаушылар ',
            'Other categories': ' Басқа санаттар ',
            Clear: 'Ошіру',
            Done: 'Дайын',
            'Enter name or code of category': 'Санат кодын немесе атын еңгізіңіз',
            'Passengers count': 'жолаушылар саны',
            Date_from: 'қайту күні',
            Resident: 'тұрғыны',
            ResidentInfo: 'Шетел азаматтары үшін <span>EUR, USD, RUB валюталары</span> қолжетімді'
        },
        SearchFormDatepicker: {
            Oops: 'Ой',
            Clear: 'Ошіру',
            'One way': 'Бір бағытқа',
            Outbound: ' Қайда ',
            Return: ' Қайдан',
            'Approximate prices for 1 person for 1 direction': '1 адамға 1 жаққа бағдарлы бағалар ағымдағы айға ғана көрсетілген, келесі айлардың құнын тексеру үшін іздеуді жүзеге асыруды сұраймыз.'
        },
        SeatMap: {
            'Less than 24 hours left before the flight': ' Ұшуға 24 сағаттан аз уақыт қалды ',
            'You can select seats using the online registration service after issuing tickets': ' Билетті сатып алған соң ғана орынды онлайн тіркеу арқылы таңдауға болады ',
            'Next flight': 'Келесі рейс',
            'Business class': 'Бизнес класс',
            'Economy class': 'Эконом класс ',
            'First class': 'Бірінші класс ',
            Business: 'Бизнес- класс ',
            Economy: 'Эконом- класс ',
            First: ' Бірінші класс ',
            seat_0: '{{count}} орын',
            seat_1: '{{count}} орын',
            Seats: 'орын',
            'Select passenger': 'Жолаушыны таңдаңыз',
            Cancel: 'Бас тарту',
            'Take this seat': ' Орын таңдау ',
            'Change seat': ' Орынды өзгерту',
            Recommend: 'Ұсынамыз',
            'Fly together!': 'Бірге ұшыңыз',
            'Show recommended seats': ' Ұсынылатын орындар ',
            Total: 'Барлығы',
            'Choose your seat': ' Орынды таңдаңыз ',
            'Choose myself': 'Өзім таңдаймын',
            Confirm: 'Растау',
            'Recommended seats': ' Ұсынамыз ',
            Clear: ' Өшіру ',
            'Clear all': 'Барлығын өшіру',
            'Passenger, {{type}}': 'Жолаушы, {{type}}',
            'Next Flight': 'Келесі рейс',
            Back: 'Артқа',
            from: 'Қайдан',
            'In the event of an aircraft replacement, passengers who have paid for the "seat selection" service will be provided with seats on the plane that are similar in price and comfort, while maintaining the following parameters': ' Әуе кемесі ауыстырылған жағдайда \'орын таңдау\' қызметіне ақы төлеген жолаушыларға сол параметрлерді сақтай отырып, бағасы мен жайлылығы бойынша ұқсас ұшақта орын берілетін болады ',
            Note: ' Маңызды ',
            'Pay attention!': 'Құрметті жолаушылар,',
            'The airline reserves the right to replace the aircraft.': '',
            'In the event of an aircraft replacement, passengers who have paid for the “seat selection” service will be provided with seats on the plane that are similar in price and comfort, while maintaining the following parameters': 'Ұшу қауіпсіздігін қамтамасыз ету мақсатында авиакомпания қажет болған жағдайда жолаушылардың мынадай санатын авариялық жерден ауыстыруға құқылы',
            '- Cost of services;': '- 18 жасқа толмаған жолаушылар;',
            '- Location of the place relative to the window / aisle;': '- 2 жасқа дейінгі балалары бар жолаушылар;',
            '- Joint seating, if one was originally selected;': '- жүкті әйелдер;',
            '- Placement in the front row of the cabin, if one was originally selected.': '- салонда багажы бар жолаушылар (мысалы, музыкалық аспабы бар);\n- мүмкіндігі шектеулі жолаушылар.'
        },
        Segment: {
            Gate: ' Gate ',
            'Airport check-in': ' Әуежайда тіркелу '
        },
        SelectedPassenger: {
            Gender: 'Жынысы',
            Female: 'Әйел',
            ResidencePermit: 'Мекендеу қағазы'
        },
        SelectSegment: {
            Confirm: 'Растау',
            'Check-in for flights': ' Рейске тіркелу '
        },
        Service: {
            from: 'Бастап',
            Clear: 'Өшіру'
        },
        ServiceRecommendation: {
            Total: 'Барлығы',
            Price: 'Баға',
            Details: 'Толық ақпарат',
            'We offer you this meal': 'Ұсынылган тағамдар ұсынылады '
        },
        ServiceWithSegments: {
            'Next flight': 'Келесі рейс'
        },
        Stepbar: {
            Flights: 'Рейстер'
        },
        Toolbar: {
            'Oops...': 'Ой...',
            'OK, thank you': 'Түсінікті, рахмет'
        },
        translation: {
            'It looks like something went wrong': ' Бір нәрсе қате болған сияқты ',
            'Please reload the page or start a new search': ' Парақшаны жаңартыңыз немесе жаңа іздеуді бастаңыз.',
            'Reload the page': ' Парақшаны жаңарту ',
            Refresh: 'Жаңарту',
            'Please start a new search using the form above': ' Жоғарыдағы іздеу формасын қолданып жаңа іздеуді бастауыңызды өтінеміз. '
        },
        Traveller: {
            firstName: 'Аты',
            email: 'Электрондық пошта мекен жайы',
            'Please enter a valid email': ' Дұрыс электрондық пошта мекенжайын енгізіңіз ',
            'Frequent Flyer': ' бонустық бағдарлама картасын қосу',
            'An error occurred while executing the request, please try again': ' Сұраныс кезінде қателік туындады, қайтадан көріңіз '
        },
        VipServiceSelect: {
            'Different for other flight?': ' Әрбір рейс үшін бөлек баптаңыз ',
            Cancel: 'Бас тарту',
            Confirm: 'Растау'
        },
        VisaForm: {
            'Incorrect date': 'Қате күндер',
            'Latin characters only': 'Тек латын әріптері'
        },
        FlightStatus: {
            'Flight Status': 'Ұшу картасы',
            'The latest data update was performed': 'Ең соңғы деректер жаңартылды',
            'City / Airport / Flight number': 'Қала / Әуежай / Ұшақ нөмірі'
        }
    },
    de: {
        RetrieveBooking: {
            'Booking ID': 'Bestellnummer oder Buchungscode'
        },
        Checkout: {
            'fare rules': 'tarifregeln'
        },
        SearchForm: {
            Resident: 'Einwohner',
            ResidentInfo: 'Für ausländische Staatsbürger stehen die <span>Währungen EUR, USD und RUB</span> zur Verfügung'
        }
    },
    tr: {
        FlightStatus: {
            'Flight Status': 'Uçuş haritası',
            'The latest data update was performed': 'En son veri güncellemesi tarafından gerçekleştirildi',
            'City / Airport / Flight number': 'Şehir / Havalimanı / Uçuş numarası'
        },
        SearchForm: {
            Resident: 'İkamet eden',
            ResidentInfo: 'Yabancı vatandaşlar için <span>EUR, USD ve RUB para</span> birimleri mevcuttur.'
        }
    },
    zh
};
const availableLoadersLocales = ['en', 'ru', 'kz'];
const loaderLocale = availableLoadersLocales.includes(currentLocale) ? currentLocale : Locale.English;
export const promoLoaders = [
    {
        type: CardType.City,
        image: `https://cdn.websky.aero/content/frontend/images/DV-LoadingBanners/Istanbul-${loaderLocale}.jpg`
    },
    {
        type: CardType.Aircraft,
        image: `https://cdn.websky.aero/content/frontend/images/DV-LoadingBanners/comfort-${loaderLocale}.jpg`
    },
    {
        type: CardType.WiFi,
        image: `https://cdn.websky.aero/content/frontend/images/DV-LoadingBanners/25years-${loaderLocale}.jpg`
    }
];
export const defaultSteps = {
    flights: {
        label: i18next.t('Stepbar:Flights')
    },
    passengers: {
        label: i18next.t('Stepbar:Passengers')
    },
    seats: {
        label: i18next.t('Stepbar:Seats')
    },
    baggage: {
        label: i18next.t('Stepbar:Baggage')
    },
    payment: {
        label: i18next.t('Stepbar:Payment')
    }
};
export const config = {
    global: {
        showIsActualizationResultOk: false,
        companyInfo,
        allowPromoCodes: true,
        maxBaggagePerPassenger: 4,
        i18n,
        regex: {
            PNR_OR_TICKET_NUMBER_REGEX: /^[A-Z]{6,6}$/,
            PASSENGER_LAST_NAME_LATIN_REGEX: /^[- a-zA-Z0-9]{1,41}$/
        },
        gtmId: isProd ? 'GTM-TNJK3NJH' : undefined,
        socialAuthMethods: [
            {
                service: OauthServiceType.Google,
                isAvailable: true
            },
            {
                service: OauthServiceType.Facebook,
                isAvailable: true
            }
        ],
        authMethods: {
            phone: false,
            ffp: false
        },
        findOrderURL: `https://tickets.scat.kz/${uncorvertedCurrentLocale}/booking/#/find`,
        reserveEngines: {
            siteUrl: `https://tickets.scat.kz/${uncorvertedCurrentLocale}`
        },
        yandexMetrikaId: isProd ? '92447901' : undefined
    },
    Account: {
        engineURL: './booking',
        showUserPhoneInput: false
    },
    Engine: {
        defaultSteps,
        calendarDaysCount: 5,
        searchFormURL: isProd ? './' : null,
        checkInURL: './check-in',
        promoLoaders: promoLoaders,
        showRefundRedirectButton: false,
        exareShowPassengersStep: false
    },
    SearchForm: {
        showPriceGraph: false,
        enableComplexRoute: true,
        showPricesInDatepicker: true,
        maxPassengersCount: 7,
        selectableCurrencies: [
            {
                code: Currency.KZT,
                symbol: CurrencySymbols.KZT
            },
            {
                code: Currency.EUR,
                symbol: CurrencySymbols.EUR
            },
            {
                code: Currency.USD,
                symbol: CurrencySymbols.USD
            },
            {
                code: Currency.RUB,
                symbol: CurrencySymbols.RUB
            }
        ]
    },
    FlightStatus: {
        playMarketURL: '',
        appStoreURL: ''
    }
};
