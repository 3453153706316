import * as React from 'react';
import { SearchForm } from '@websky/core';
import { ThemeProvider } from '@websky/core/src/theme';
import { RenderersProvider } from '@websky/core/src/renderProps';
import theme from '../../theme/theme';
import searchFormTheme from '../../theme/searchFormTheme';
import { searchFormRenderers } from '../renderProps';
import '../../theme/variables.css';
import Head from '@websky/core/src/Head';
import Geolocation from '../Engine/Geolocation/Geolocation';
import PromoCode from '@websky/core/src/SearchForm/components/SearchForm/Bottom/PromoCode/PromoCode';
import css from './SearchForm.css';
const SearchFormComponent = () => {
    return (React.createElement(ThemeProvider, { value: theme },
        React.createElement(ThemeProvider, { value: searchFormTheme },
            React.createElement(RenderersProvider, { value: searchFormRenderers },
                React.createElement(Head, null),
                React.createElement(Geolocation, null,
                    React.createElement("div", { className: css.container },
                        React.createElement("div", { className: css.wrapper },
                            React.createElement(SearchForm, { renderPromoCode: () => {
                                    return (React.createElement(PromoCode, { className: css.promoCode, promoCodeClassName: css.linkWithIcon }));
                                } }))))))));
};
export default SearchFormComponent;
