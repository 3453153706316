import { useEffect, useMemo, useState } from 'react';
import { useLocationByUserPositionQuery } from '@websky/graphql';
import { getUserLocation } from '../utils';
export const useGeolocation = () => {
    const [coordinates, setCoordinates] = useState(null);
    const { data, loading } = useLocationByUserPositionQuery({
        fetchPolicy: 'no-cache',
        variables: {
            userPosition: coordinates
        },
        skip: !coordinates
    });
    useEffect(() => {
        getUserLocation(setCoordinates);
    }, []);
    const countryCode = useMemo(() => {
        var _a, _b, _c;
        if (!((_a = data === null || data === void 0 ? void 0 : data.LocationByUserPosition) === null || _a === void 0 ? void 0 : _a.length)) {
            return null;
        }
        return (_c = (_b = data.LocationByUserPosition[0]) === null || _b === void 0 ? void 0 : _b.country) === null || _c === void 0 ? void 0 : _c.codeISO;
    }, [data === null || data === void 0 ? void 0 : data.LocationByUserPosition]);
    return { loading, countryCode };
};
